$mobile-padding: 6.25%;
.landing-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0 $mobile-padding;
  max-width: 1155px;
  box-sizing: border-box;
  @media (min-width: $break-point-md) {
    padding: 0 81px 0 85px;
  }
  @media (min-width: $break-point-lg) {
    width: auto;
  }
  .side-left {
    width: 114px;
    img {
      cursor: pointer;
    }
  }
  button {
    border: 0;
    outline: 0;
  }
}

.landing-container {
  box-sizing: border-box;
  $scroll-position: 80px;
  @media (min-width: $break-point-lg) {
    width: auto;
  }
  @media (max-width: $break-point-md) {
    width: 100vw;
  }
  .register-inquiry-btn {
    position: fixed;
    width: 9rem;
    height: auto;
    bottom: 1rem;
    right: 1rem;
    border: 0;
    z-index: 300;
    outline: 0;
    @media (min-width: $break-point-md) {
      width: 135px;
      right: 2rem;
      bottom: 5px;
    }
    p {
      position: relative;
      top: -6.4rem;
      max-width: 80%;
      margin: 0 auto;
      color: white;
      font-size: 1.5rem;
      font-weight: 800;
      line-height: normal;
      @media (min-width: $break-point-md) {
        top: -96px;
        font-size: 21px;
      }
      &.double-line {
        top: -7.3rem;
        font-size: 1.6rem;
        @media (min-width: $break-point-md) {
          top: -110px;
          font-size: 23px;
        }
      }
    }
  }
  .introduction-sentbe {
    $content-color: #444447;
    $sub-color: #323335;
    padding: 0 1em;
    margin-top: 8rem;
    margin-bottom: 8.5rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 1155px;
    color: black;
    @media (min-width: $break-point-md) {
      padding-top: $scroll-position;
      padding-left: 6vw;
      margin-top: -$scroll-position;
      margin-bottom: 100px;
    }
    .introduction-company {
      display: none;
      color: $sub-color;
      font-size: 35px;
      letter-spacing: -1.12px;
      @media (min-width: $break-point-md) {
        display: block;
      }
    }
    .introduction-company-mobile {
      display: block;
      margin-bottom: 1.4rem;
      color: #232429;
      font-size: 3.1rem;
      font-weight: 800;
      letter-spacing: -0.106rem;
      text-align: center;
      @media (min-width: $break-point-md) {
        display: none;
      }
    }
    .sentbe-txt {
      display: none;
      margin-top: 9.3px;
      margin-bottom: 20.7px;
      @media (min-width: $break-point-md) {
        display: block;
      }
    }
    .introduction-subtitle {
      margin-bottom: 44px;
      color: $content-color;
      font-size: 17px;
      letter-spacing: -0.58px;
    }
    .introduction-subtitle-mobile {
      margin-bottom: 44px;
      padding: 0 $mobile-padding;
      color: #555555;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.44;
      letter-spacing: 0.016rem;
      text-align: center;
      word-break: keep-all;
    }
    .introduction-pc {
      display: none;
      @media (min-width: $break-point-md) {
        display: block;
      }
    }
    .introduction-mobile-wrapper {
      display: block;
      @media (min-width: $break-point-md) {
        display: none;
      }
    }
    .introduction-elements-container {
      border: none;
      max-width: 730px;
      padding-left: 20px;
      margin-bottom: 75px;
      @media (min-width: $break-point-md) {
        border-left: 10px solid black;
      }
      .introduction-elements-title {
        display: inline-block;
        margin-bottom: 9px;
        font-size: 35px;
        font-weight: 800;
        letter-spacing: -1.05px;
        line-height: 1;
      }
      .introduction-elements-content {
        position: relative;
        bottom: -8px;
        color: $content-color;
        font-size: 17px;
        letter-spacing: -0.58px;
        line-height: 2;
      }
    }
    .introduction-sentbe-info {
      display: none;
      @media (min-width: $break-point-md) {
        display: block;
        margin-bottom: 3px;
        color: $sub-color;
        font-size: 15px;
        letter-spacing: -0.44px;
      }
    }
    .introduction-sentbe-link {
      display: none;
      @media (min-width: $break-point-md) {
        display: block;
        font-size: 22px;
        letter-spacing: -0.68px;
        text-decoration: underline;
      }
    }
  }
  .introduction-mobile {
    display: block;
    position: relative;
    margin-bottom: 8.7rem;
    @media (min-width: $break-point-md) {
      display: none;
    }
    .swiper-info-wrapper {
      text-align: center;
    }
    .introduction-elements-title {
      display: inline-block;
      white-space: pre-wrap;
      margin: 0 auto;
      padding-bottom: 1.7rem;
      width: fit-content;
      height: fit-content;
      color: #232429;
      text-align: center;
      font-size: 2.3rem;
      font-weight: 800;
      letter-spacing: 0.46px;
      line-height: 1.22;
      border-bottom: solid 0.4rem #232429;
      @media (min-width: $break-point-md) {
        padding-bottom: 16px;
      }
    }
    .element-title-wrapper {
      position: relative;
      height: 8.2rem;
    }
    .multiple-lines {
      border-bottom: none;
      padding-bottom: 0.5rem;
    }
    .single-line {
      margin-top: 2rem;
      padding-bottom: 0.5rem;
    }
    .under-multiple-line {
      position: relative;
      top: -1rem;
      height: 0.4rem;
      background-color: #232429;
      border: none;
    }
    .fx {
      max-width: 240px;
    }
    .singapore {
      max-width: 190px;
    }
    .aml {
      max-width: 300px;
    }
    .security {
      max-width: 230px;
    }
    .introduction-elements-content {
      margin: 3rem auto 0;
      width: 70%;
      height: 13.5rem;
      color: #555555;
      font-size: 1.6rem;
      line-height: 1.69;
      letter-spacing: -0.08px;
      text-align: center;
      word-break: keep-all;
    }
    .introduction-pagination {
      position: relative;
      width: fit-content;
      top: 2.7rem;
      left: calc(50% - 34px);
    }

    .swiper-pagination-bullet {
      width: 1.1rem;
      height: 1.1rem;
      margin-right: 0.8rem;
      background-color: #dadada;
      opacity: unset;
      outline: none;
    }
    .swiper-pagination-bullet:last-child {
      margin-right: unset;
    }
    .swiper-pagination-bullet-active {
      background-color: black;
    }
    .swiper-btn {
      position: absolute;
      top: 12rem;
      color: #dedede;
      font-size: 4.3rem;
      outline: none;
      z-index: 10;
    }
    .prev-icon {
      height: auto;
      width: 2.5rem;
      left: 4%;
    }
    .next-icon {
      right: 4%;
      height: auto;
      width: 2.5rem;
    }
  }
  .main-title-container {
    position: relative;
    left: 0;
    width: 100vw;
    @media (min-width: $break-point-md) {
      left: -60px;
    }
  }
  .main-title {
    width: 100%;
    margin: 0 auto;
    padding: 15rem $mobile-padding 0;
    color: white;
    text-align: center;
    .text-of {
      font-weight: 300;
    }
    @media (min-width: $break-point-md) {
      max-width: 1155px;
    }
    .main-title-pc {
      display: none;
      @media (min-width: $break-point-md) {
        display: block;
      }
      h1 {
        width: 100%;
        margin: 0 auto;
        font-size: 66px;
        line-height: 80px;
        letter-spacing: -2.24px;
      }

      h2 {
        margin-top: 18px;
        margin-bottom: 111px;
        font-size: 22px;
        line-height: 38px;
        letter-spacing: -0.7px;
      }
      .br-removed {
        display: none;
      }
    }
    .main-title-mobile {
      display: block;
      @media (min-width: $break-point-md) {
        display: none;
      }
      h1 {
        width: 100%;
        font-size: 3.9rem;
        font-weight: normal;
        letter-spacing: -0.134rem;
        line-height: 1.19;
      }
      h2 {
        width: 100%;
        margin: 0.9rem auto 4rem;
        color: white;
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 1.61;
        letter-spacing: -0.062rem;
      }
    }
    .buildings-img {
      position: relative;
      top: 2rem;
      margin: 0 auto 150px;
      max-width: unset;
      height: 19.9rem;
      background: no-repeat url("../images/img_web_main.jpg") 0 / 150%;
      overflow: hidden;
      @media (min-width: $break-point-md) {
        top: unset;
        max-width: 996px;
        height: 426px;
        background-size: 100%;
      }
    }
  }
  .main-title-container::after {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100vw;
    height: calc(100% - 8rem);
    background-color: #232429;
    content: "";
    z-index: -1;
    @media (min-width: $break-point-md) {
      width: 100vw;
      height: calc(100% - 213px);
    }
  }
  .review-section-container {
    position: relative;
    left: 0;
    width: 100vw;
    height: fit-content;
    @media (min-width: $break-point-md) {
      left: -60px;
      height: 795px;
    }
  }
  .review-section {
    position: relative;
    margin: 11.9rem auto 0;
    padding: 5rem $mobile-padding 8rem;
    height: fit-content;
    box-sizing: border-box;
    @media (min-width: $break-point-md) {
      margin-top: 144px;
      padding: 0 81px 0 85px;
    }
    .review-rolling-component {
      position: relative;
      height: fit-content;
      @media (min-width: $break-point-md) {
        top: 200px;
      }
    }
    .swiper-pagination {
      position: relative;
      left: unset;
      top: -0.5rem;
      margin-left: unset;
      @media (min-width: $break-point-md) {
        position: absolute;
        left: 50%;
        top: -30px;
        margin-left: -22px;
      }
      .swiper-pagination-bullet {
        margin-right: 1rem;
        width: 1rem;
        height: 1rem;
        background-color: white;
        opacity: 0.53;
        outline: none;
        @media (min-width: $break-point-md) {
          margin-right: 7px;
        }
      }
      .swiper-pagination-bullet:last-child {
        margin-right: 0;
      }
      .swiper-pagination-bullet-active {
        background-color: white;
        opacity: 1;
      }
    }
    .service-review-mobile {
      display: block;
      color: white;
      font-size: 1.5rem;
      letter-spacing: 0.011rem;
      text-align: center;
      @media (min-width: $break-point-md) {
        display: none;
      }
    }
    .review-divider-mobile {
      display: block;
      width: 12rem;
      height: 0.4rem;
      margin: 0.45rem auto;
      background-color: white;
      @media (min-width: $break-point-md) {
        display: none;
      }
    }
    .swiper-container {
      height: fit-content;
      text-align: center;
      vertical-align: middle;
      overflow: hidden;
      @media (min-width: $break-point-md) {
        height: 420px;
      }
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .swiper-slide-active .review-card {
        h1 {
          animation: moderate-fade-in 0.75s ease-out 0.25s backwards;
        }
        p {
          animation: moderate-fade-in 0.75s ease-out 0.25s backwards;
        }
        @media (min-width: $break-point-md) {
          margin-left: 5px;
          margin-right: 5px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
        .review-reviewer,
        .review-divider-mobile {
          animation: moderate-fade-in 0.75s ease-out 0.25s backwards;
        }
      }

      .review-card {
        width: 100%;
        max-width: 996px;
        margin: 0 auto;
        height: fit-content;
        background-color: transparent;
        border: none;
        box-shadow: none;
        @media (min-width: $break-point-md) {
          height: 410px;
          background-color: white;
        }

        .el-card__body {
          display: table;
          width: 100%;
          height: 100%;
          padding: 0;
        }
        .review-article {
          top: 3.65rem;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          word-break: keep-all;
          @media (min-width: $break-point-md) {
            top: unset;
            padding: 0 110px;
          }
          .service-review {
            display: none;
            @media (min-width: $break-point-md) {
              display: inline-block;
              margin-bottom: 30pt;
              color: black;
              border-bottom: 4px solid black;
              font-size: 12px;
              font-weight: 800;
            }
          }
          .review-summary {
            display: none;
            @media (min-width: $break-point-md) {
              display: block;
              width: 100%;
              height: fit-content;
              margin-bottom: 20pt;
              color: black;
              font-size: 46px;
              font-weight: bold;
              line-height: normal;
            }
          }
          .review-summary-mobile {
            display: block;
            width: 27rem;
            height: fit-content;
            margin: 3rem auto 0;
            color: white;
            font-size: 3.65rem;
            font-weight: bold;
            line-height: normal;
            letter-spacing: -0.248rem;
            word-break: keep-all;
            word-wrap: break-word;
            @media (min-width: $break-point-md) {
              display: none;
            }
          }
          .mobile-card {
            max-width: 32.2rem;
            padding-bottom: 3.3rem;
            margin: 3.65rem auto;
            background-color: white;
            box-shadow: 0 1.5rem 2.9rem 0 rgba(0, 0, 0, 0.08);
            @media (min-width: $break-point-md) {
              max-width: unset;
              padding-bottom: unset;
              margin: unset;
              box-shadow: unset;
            }
          }
          .review-content {
            display: none;
            @media (min-width: $break-point-md) {
              display: block;
              margin-bottom: 15pt;
              color: #292929;
              font-size: 20px;
              line-height: 1.5;
            }
          }
          .review-content-mobile {
            display: block;
            padding: 2.5rem;
            padding-bottom: 0;
            font-size: 1.95rem;
            text-align: center;
            letter-spacing: -0.015rem;
            line-height: 1.59;
            word-break: keep-all;
            @media (min-width: $break-point-md) {
              display: none;
            }
          }
          .review-divider-mobile {
            display: block;
            width: 2.65rem;
            height: 0.15rem;
            margin: 2.5rem auto 0;
            background-color: #226df3;
            @media (min-width: $break-point-md) {
              display: none;
            }
          }

          .review-reviewer {
            margin: 1.3rem auto 0;
            width: 55%;
            color: #226df3;
            font-size: 1.5rem;
            font-weight: bold;
            @media (min-width: $break-point-md) {
              width: 100%;
              color: #5886cb;
              font-size: 16px;
              font-weight: 600;
            }

            .dash {
              display: none;
              @media (min-width: $break-point-md) {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .review-section-container::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    opacity: 0.8;
    background-color: black;
    content: "";
    @media (min-width: $break-point-md) {
      height: 401px;
      opacity: 0.7;
    }
  }
  .review-section-container::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: url("../images/damir.jpg") no-repeat 0 90% / cover;
    z-index: -1;
    content: "";
    @media (min-width: $break-point-md) {
      height: 401px;
      background-position: 0 80%;
    }
  }
  .presents-title {
    margin: -14rem auto 0;
    padding-top: 100px;
    width: 20rem;
    text-align: center;
    @media (min-width: $break-point-md) {
      width: 100%;
      margin-bottom: 70px;
      margin-top: -$scroll-position;
    }
    .presents-title-rest {
      color: black;
      font-size: 3.5rem;
      font-weight: 800;
      @media (min-width: $break-point-md) {
        font-weight: bold;
        font-size: 55px;
        letter-spacing: -0.012rem;
        line-height: 1.12;
      }
    }
    .img-txtsentbiz {
      width: auto;
      height: 2.8rem;
      @media (min-width: $break-point-md) {
        height: 41px;
      }
    }
  }
  .presents-contents-pc {
    display: none;
    @media (min-width: $break-point-md) {
      display: block;
      margin-bottom: 162px;
    }
  }
  .presents-elements {
    display: inline-block;
    flex-basis: 400px;
    border-right: dashed 2px rgba(141, 141, 141, 0.44);
    .presents-elements-title {
      margin-bottom: 18px;
      color: black;
      font-size: 28px;
      font-weight: 800;
      text-align: center;
      letter-spacing: -0.88px;
    }
    .presents-elements-content {
      color: #7a7a7a;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      letter-spacing: -0.51px;
    }
  }
  .presents-pagination {
    position: relative;
    display: block;
    left: unset;
    top: 2rem;
    text-align: center;
  }

  .swiper-pagination-bullet {
    width: 1.1rem;
    height: 1.1rem;
    margin-right: 0.8rem;
    background-color: #dadada;
    opacity: unset;
    outline: none;
  }

  .swiper-pagination-bullet:last-child {
    margin-right: unset;
  }

  .swiper-pagination-bullet-active {
    background-color: black;
  }

  .presents-swiper-container {
    margin-top: 3.6rem;
    color: black;
    .presents-elements-title {
      margin: 0 auto 2.5rem;
      width: fit-content;
      border-bottom: solid 8px black;
      font-size: 48px;
      font-weight: 800;
      letter-spacing: -1.63px;
    }
    .presents-elements-content {
      margin: 0 auto;
      width: 70%;
      color: #555555;
      text-align: center;
      font-size: 40px;
      line-height: 1.5;
      letter-spacing: -1.36px;
      word-break: keep-all;
    }
  }
  .presents-flex-container {
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
  }
  .presents-elements:last-child {
    border: none;
  }
  .presents-contents-mobile {
    display: block;
    position: relative;
    height: fit-content;
    margin-bottom: 25rem;
    @media (min-width: $break-point-md) {
      display: none;
    }
    .swiper-btn {
      position: absolute;
      top: 7rem;
      font-size: 95px;
      color: #dedede;
      outline: none;
      z-index: 10;
    }
    .prev-icon {
      left: $mobile-padding;
      height: auto;
      width: 2.5rem;
    }
    .next-icon {
      right: $mobile-padding;
      height: auto;
      width: 2.5rem;
    }
    .presents-elements-title {
      font-size: 2.5rem;
      border-bottom: solid 0.4rem black;
    }
    .presents-elements-content {
      font-size: 2.2rem;
    }
  }
  .ask-register-container {
    position: relative;
    left: 0;
    width: 100vw;
    @media (min-width: $break-point-md) {
      left: -60px;
    }
  }
  .ask-register {
    $title-color: #6096ff;
    position: relative;
    display: block;
    padding: 3.1rem;
    padding-top: 7rem;
    padding-bottom: 8.1rem;
    color: white;
    @media (min-width: $break-point-md) {
      display: flex;
      justify-content: space-between;
      margin-right: auto;
      margin-left: auto;
      margin-top: 145px;
      padding: 145px 81px 125px 85px;
      max-width: 1155px;
    }
    .info {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      .email-wrapper {
        order: 2;
        @media (min-width: $break-point-md) {
          order: unset;
        }
        div {
          display: inline;
          margin-right: 0.75rem;
          font-size: 1.45rem;
          @media (min-width: $break-point-md) {
            display: block;
            font-size: 18px;
          }
        }
      }
    }
    .left {
      @media (min-width: $break-point-md) {
        position: relative;
      }
    }
    .right {
      width: 100%;
      @media (min-width: $break-point-md) {
        width: 462px;
        margin-top: 8px;
      }
      .form-item-policy {
        margin-bottom: 5px;
      }
      .checkbox-wrapper {
        display: table;
        margin-top: 1rem;
        @media (min-width: $break-point-md) {
          display: block;
          margin-top: unset;
        }
      }
      .policy-title {
        display: table-cell;
        vertical-align: middle;
        padding-left: 0.5rem;
        padding-right: 3rem;
        font-size: 1.6rem;
        line-height: 1;
        @media (min-width: $break-point-md) {
          display: inline;
          padding-right: unset;
          padding-left: unset;
          font-size: 16px;
        }
      }
      input.form-checkbox {
        position: relative;
        top: 4px;
        height: 20px;
        width: 20px;
        margin-left: unset;
        @media (min-width: $break-point-md) {
          display: table-cell;
          width: 2.4rem;
          height: 2.4rem;
        }
      }
      .more-btn {
        position: absolute;
        display: inline-block;
        right: 0;
        margin-left: 1.5rem;
        color: #acacac;
        font-size: 1.1rem;
        text-decoration: underline;
        @media (min-width: $break-point-md) {
          display: none;
        }
      }
      .policy {
        display: none;
        @media (min-width: $break-point-md) {
          display: block;
          margin-top: -4px;
          color: #9097a9;
          font-size: 10px;
          font-weight: 500;
          line-height: 1.6;
        }
      }
      .policy-mobile {
        display: block;
        margin-top: -4px;
        color: #9097a9;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.6;
        @media (min-width: $break-point-md) {
          display: none;
        }
      }
      .ask-btn {
        width: 100%;
        height: 68px;
        margin-top: 1rem;
        background-color: #4d7fe0;
        font-size: 20px;
        font-weight: bold;
        @media (min-width: $break-point-md) {
          margin-top: 13px;
        }
        &:disabled {
          opacity: unset;
          color: rgba(255, 255, 255, 0.2);
          border-color: #58667f;
          background-color: #58667f;
        }
      }
    }
    .sentbiz {
      font-size: 2.65rem;
      font-weight: bold;
      letter-spacing: 0.138rem;
      line-height: 1.8;
      @media (min-width: $break-point-md) {
        margin-bottom: 15px;
        font-size: 60px;
        letter-spacing: 1.57px;
        line-height: 1.08;
      }
    }
    .inquiry {
      margin-top: -20px;
      margin-bottom: 2rem;
      font-size: 4.7rem;
      font-weight: 100;
      letter-spacing: -0.177rem;
      @media (min-width: $break-point-md) {
        margin-bottom: 223px;
        font-size: 71px;
        font-weight: 100;
        letter-spacing: -2.42px;
      }
    }
    .info {
      font-size: 18px;
      font-weight: bold;
      @media (min-width: $break-point-md) {
        position: absolute;
        bottom: 0;
      }
      .title {
        color: $title-color;
      }
      .title:nth-child(3) {
        margin-top: 42px;
      }
    }
    .form-items-flex {
      display: flex;
      flex-wrap: wrap;
      margin-top: 4.4rem;
      @media (min-width: $break-point-md) {
        margin-top: unset;
      }
    }
    .latter-form-items-flex {
      margin-top: unset;
    }
    .forms-in-flex {
      flex: 1 0 40%;
      vertical-align: middle;
      margin-bottom: 10px;
      .el-textarea__inner,
      .el-input__inner {
        padding-top: 2px;
        border-radius: 2px;
        border: none;
        font-size: 1.8rem;
        @media (min-width: $break-point-md) {
          font-size: 16px;
        }
      }
      .el-input__inner::placeholder,
      .el-textarea__inner::placeholder {
        color: #8c8b8b;
      }
    }
    .former-forms-in-flex {
      textarea {
        height: 103px;
      }
      .el-select {
        width: 100%;
      }
    }
    .optional-pharase {
      display: block;
      margin-top: -4px;
      margin-bottom: 0.4rem;
      color: #9097a9;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.6;
      @media (min-width: $break-point-md) {
        font-size: 12px;
      }
    }
    .former-forms-in-flex:nth-child(even) {
      margin-right: 10px;
    }
    .latter-forms-in-flex:nth-child(odd) {
      margin-right: 10px;
    }
    .inquiry-text-area {
      margin-right: unset !important;
      textarea {
        padding-top: 1.1rem;
        @media (min-width: $break-point-md) {
          height: 103px;
          padding-top: 16px !important;
        }
      }
    }
    .latter-forms-in-flex {
      flex: 1 0 47%;
    }
    .former-forms-in-flex.no-margin {
      margin-right: 0;
    }
    .full-width-item {
      flex-basis: 100%;
    }
  }
  .ask-register-container::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: #2e3549;
    z-index: -1;
    content: "";
  }

  .main-footer-container {
    position: relative;
    left: 0;
    width: 100vw;
    @media (min-width: $break-point-md) {
      left: -60px;
    }
  }
  .main-footer {
    position: relative;
    margin: 0 auto;
    height: 48rem;
    max-width: 1155px;
    padding: 0 3.2rem;
    @media (min-width: $break-point-md) {
      position: static;
      height: 350px;
      padding: 0 81px 0 85px;
    }
  }
  .main-footer::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: #242938;
    z-index: -1;
    content: "";
    @media (min-width: $break-point-md) {
      height: 350px;
    }
  }
  .footer-introduction-table {
    padding-top: 33px;
    @media (min-width: $break-point-md) {
      margin-bottom: 74px;
    }
    th {
      color: #dbdfe9;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: left;
      @media (min-width: $break-point-md) {
        font-size: 15px;
      }
    }
    td {
      padding-left: 25px;
      color: white;
      font-size: 1.1rem;
      font-weight: 300;
      @media (min-width: $break-point-md) {
        font-size: 13px;
      }
    }
  }
  .footer-divider {
    display: block;
    position: relative;
    left: -3.2rem;
    width: 100vw;
    background-color: #2e3549;
    @media (min-width: $break-point-md) {
      position: absolute;
      top: 90px;
      left: 0;
      height: 1px;
    }
  }
  .footer-flex-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 92%;
    font-size: 1.2rem;
    font-weight: 300;
    @media (min-width: $break-point-md) {
      flex-direction: row;
      font-size: 15px;
    }
    .footer-flex-title {
      margin-top: 2.2rem;
      color: #6096ff;
      font-weight: bold;
      @media (min-width: $break-point-md) {
        margin-top: unset;
      }
    }
    .footer-flex-content {
      color: white;
      font-weight: 400;
      &.pc {
        display: none;
        @media (min-width: $break-point-md) {
          display: block;
        }
      }
      &.mobile {
        display: block;
        @media (min-width: $break-point-md) {
          display: none;
        }
      }
    }
  }
  .footer-copyright {
    margin-top: 60px;
    text-align: center;
    font-size: 1.15rem;
    @media (min-width: $break-point-md) {
      font-size: 15px;
    }
  }
  .partner-companies-container {
    position: relative;
    width: 100vw;
    @media (min-width: $break-point-md) {
      left: -60px;
      top: 140px;
    }
  }
  .partner-companies {
    position: relative;
    margin: 0 auto;
    padding: 7.5rem 1em 3.4rem;
    max-width: 1155px;
    @media (min-width: $break-point-md) {
      padding: 137px 81px 68px 85px;
    }
    .title {
      width: fit-content;
      margin: 0 auto;
      color: black;
      font-size: 3.5rem;
      font-weight: 800;
      line-height: 1.34;
      letter-spacing: -0.02rem;
      text-align: center;
      word-break: keep-all;
      @media (min-width: $break-point-md) {
        width: 100%;
        margin: unset;
        margin-bottom: 60px;
        font-size: 48px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
      }
    }
    .ul-flex-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0;
      margin-top: 5rem;
      @media (min-width: $break-point-md) {
        margin-top: unset;
      }
      .item {
        flex: 1 1 33%;
        margin-bottom: 30px;
        text-align: center;
        overflow: hidden;
        @media (min-width: $break-point-md) {
          flex: 1 1 24%;
        }
        img {
          margin-left: -1rem;
          max-width: 100%;
        }
      }
    }
    .ul-flex-container::after {
      width: 66%;
      content: "";
      @media (min-width: $break-point-md) {
        width: 50%;
      }
    }
  }
  .partner-companies-container::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: #f3f3f3;
    opacity: 0.2;
    content: " ";
  }
}
