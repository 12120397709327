
$spinner-base-width: 16px;
$loading-box-width: 400px;
.loading-container {
  z-index: 5000;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #dbe6fc;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-box {
    width: $loading-box-width;

    .loading-text {
      margin-top: $spinner-base-width * 2;
      text-align: center;
    }
  }
}

.lds-ellipsis {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: ($loading-box-width - ($spinner-base-width * 6))/ 2;
}

.lds-ellipsis div {
  position: absolute;
  width: $spinner-base-width;
  height: $spinner-base-width;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: $spinner-base-width / 1.6;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: $spinner-base-width / 1.6;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: $spinner-base-width * 2.5;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: $spinner-base-width * 4.3;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate($spinner-base-width * 1.8, 0);
  }
}
