.affiliate {
  &-wrapper {
    display: block;
    width: 850px;
    margin: 0 auto;
  }

  &-account-wrapper {
    h2 {
      font-weight: bold;
      font-size: 1.8rem;
    }

    h3 {
      font-weight: bold;
      font-size: 1.6rem;
    }

    .content {
      margin-top: 20px;
    }

    .add-account {
      margin: 70px 0;

      .account-form {
        margin-top: 50px;

        .members {
          display: flex;
          align-items: center;

          .member {
            width: 100%;
          }

          .el-button--danger.is-plain {
            margin-top: 50px;
            background: #ffffff;
            border-color: #ffd2d0;
            color: #ff8e89;
          }
        }

        .additional {
          margin-top: 20px;
        }

        .delete {
          position: relative;
          height: 106px;
          width: 40px;

          button {
            position: absolute;
            bottom: 7px;
            color: #f24147;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.affiliate-upload-confirm {
  display: block;
  width: 520px;

  .el-dialog__body {
    padding: 0;
  }

  .el-message-box__content {
    padding: 0 60px 20px;
  }

  .el-message-box__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .el-message-box__btns .el-checkbox {
    margin-bottom: 10px;
  }

  .el-checkbox.is-checked {
    .el-checkbox__inner {
      border-color: #4c80f1;
      background-color: #4c80f1;
    }
  }

  .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: 1px solid #ccd3d6;
    border-radius: 0;
  }

  .el-checkbox__inner::after {
    width: 5px;
    height: 8px;
    border-width: 2px;
    border-color: #fff;
  }
}
