.recipient-download-container{
  padding: 48px 0;

  article {
    display: flex;
    margin-bottom: 22px;
    padding: 0 66px 0 44px;
    color: #1b1b1c;

    &.recipient-excel-button {
      padding: 28px 66px;
    }

    .subtitle {
      min-width: 157px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.74px;
    }
  }

  .other-countries {
    width: 100%;
    .el-autocomplete {
      width: 100%;
      margin-bottom: 19px;
      //border: 1px solid #e7e7e7;
      input {
        background: #fbfbfb;
      }
    }
  }
}

.checkbox-countries {
  .checkbox-countries-group {
    display: inline;
  }
  .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: 1px solid #ccd3d6;
    border-radius: 0;
    background-color: #f7f7f7;
  }
  .el-checkbox__inner::after {
    width: 5px;
    height: 8px;
    border-width: 2px;
    border-color: #fff;
  }

  .el-checkbox.is-checked {
    .el-checkbox__label{
      color: #5a8cff;
      font-weight: bold;
    }
    .el-checkbox__inner {
      border-color: #4c80f1;
      background-color: #4c80f1;
    }
  }

  .other-countries-checkbox {
    .el-checkbox__label:after {
      width: 13px;
      height: 13px;
      vertical-align: top;
      padding: 1px 6px 2px 6px;
      margin-left: 5px;
      background-color: #d8d8d8;
      content: 'X';
      color: #fff;
      font-size: 3px;
      font-weight: lighter;
      border-radius: 9px;
    }
  }

  label {
    display: inline-block;
    width: 100%;
    margin: 0 0 18px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.68px;
    @media (min-width: $break-point-sm) {
      width: 33.33%;
    }
    @media (min-width: $break-point-md) {
      width: 25%;
    }
    @media (min-width: $break-point-lg) {
      width: 16.66%;
    }
  }

  .el-divider {
    margin: 0 0 25px 0;
  }
  .modal {
    margin: 30px 0;
    label {
      width: 33.33%;
    }
  }
}

.notice-country-popover {
  width: 488px;
  padding: 10px 15px;
  border-radius: 3px;
  border: solid 1px #5a8cff;
  background-color: #5a8cff;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    font-size: 11px;
    letter-spacing: -0.58px;

    p {
      margin-bottom: 2px;
      font-weight: bold;
    }
    .yellowcolor {
      color: #ffeb00;
    }

    .el-icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

  .popper__arrow,
  .popper__arrow::after {
    border-bottom-color: #5a8cff !important;
  }
}

.recipient-excel-button {
  display: flex;
  justify-content: flex-end;
  border-top: 1px dashed #d1d1d1;
  .el-button {
    width: 286px;
    height: 78px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.95px;
    i {
      margin-right: 6px;
    }

    &.is-disabled {
      background: #9e9e9e;
      border-color: #e8e8e9;
    }
  }
}

.recipient-description-area {
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #5a8cff;

  .bluecolor {
    color: #477cf4;
    font-weight: bold;
  }
  .blackcolor {
    font-weight: bold;
  }
  dl {
    display: flex;
    align-items: center;
    padding: 31px 0;
    border-radius: 8px;
    background-color: #f8f8f8;
  }
  dt {
    display: flex;
    justify-content: center;
    width: 148px;
    color: $color-black;
    font-weight: bold;
    font-size: 16px;
  }
  dd {
    color: #575a5e;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.74px;
    margin-right: 31px;
  }
}
