
.customer-center-info {
  margin: 20px 0;
  color: #d3d3d3;
}
.customer-center-info__title {
  text-decoration: underline;
  display: block;
}
.customer-center-info__email {
  text-decoration: underline;
}
