.el-form--inline .el-form-item {
  margin-bottom: 0;
}

.el-select {
  .el-input__inner {
    background: #f8fafb;
  }
}

.el-input-group__prepend,
.el-input-group__append {
  color: $color-black;
}

.list-wrapper {
  padding-bottom: 84px;
}

.recipient-list {
  .el-row {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .country-name {
    margin: 40px 0 20px;
    font-size: 1.5rem;
  }

  .el-col + .el-col {
    .el-button {
      width: 220px;
    }
  }

  .el-select {
    .el-input__inner {
      font-size: 1.3rem;
    }
  }

  $select-base-width: 130px;
  .country-filter, .recipients-filter {
    width: $select-base-width
  }
  .recipients-filter-input + .el-form-item__error{
    margin-left: $select-base-width
  }

  .el-table {
    font-size: 1.2rem;
    thead {
      tr th {
        .el-checkbox {
          display: none;
        }
      }
    }
    .total-amount {
      display: flex;
      justify-content: space-around;
    }
  }

  .el-icon-search {
    cursor: pointer;
  }

  .hide {
    display: none;
  }

  .warning-paragraph {
    margin: 20px 0 0 10px;
    font-size: 1.4rem;
  }
}

.manual-register {
  left: $aside-width;

  .el-dialog {
    width: 90%;
  }

  .el-checkbox__inner::after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 9px;
    border-width: 2px;
  }
}

.register-table {
  padding-bottom: 84px;
  .is-error {
    color: $color-danger;
  }
  h1 {
    padding: 10px 0 20px 20px;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .title-contents {
    display: inline-block;
    h3 { display: inline; }
    .view-more {
      display: inline;
      color: #949494;
      margin-left: 10px;
      font-size: 1.4rem;
      &:focus {
        outline: none;
      }
    }
  }

  .invalid-row {
    color: $color-danger;
  }

  .manual-table {
    margin-bottom: 50px;
  }

  .el-table {
    th .cell {
      line-height: 1.7rem;
    }
    .header .suffix-icon {
      color: $color-primary;
    }

    .el-input__inner {
      text-align: center;
    }

    td {
      .el-form-item {
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
  }
}

.excel-footer {
  right: 0 !important;
  bottom: 0 !important;
  width: calc( 100vw - 220px );
  height: 84px !important;
  padding: unset;
  background: white;
  text-align: right;
  .excel {
    margin-top: 14.5px;
    margin-right: 60px;
  }
}

.upload-wrapper {
  .description {
    margin-top: 20px;
  }
}

.remittance-registered {
  .el-divider {
    margin: 0;
  }

  div {
    margin: 24px 0 20px 0;

    p {
      font-size: 2.0rem;
    }

    h1 {
      font-weight: bold;
      font-size: 2.7rem;
    }
  }
}

.flex-basis {
  flex-basis: 0;
}

.waiting-for-deposit-list {
  font-size: 1.9rem;
  .sub-head {
    margin: 0;
    padding-right: 0;
  }
  li {
    margin: 0 0 20px;
    border: 1px solid #979797;
    border-radius: 8px;
  }
  .default-wrapper {
    margin: 20px 14px 25px 30px;
  }
  .depotsit-info {
    dl {
      .amount {
        font-size: 3.3rem;
      }
      div + div {
        margin: 20px 0 0;
      }
    }
  }
  .transfer-history {
    margin: 15px 0 0;
    border-bottom: 0;
    .details {
      display: block;
      position: relative;
      margin-top: 15px;
      padding-right: 0;
      font-size: inherit;
      > span {
        display: block;
      }
      .more {
        color: inherit;
        font-size: inherit;
        text-decoration: underline;
        &::after {
          content: '>'
        }
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        .cancel {
          margin: 20px 0 0;
          padding: 0;
          color: inherit;
          text-decoration: underline;
          font-size: inherit;
        }
      }
    }
  }
}

.transfer-history {
  border-top: 1px solid #979797;
  li {
    padding: 20px 15px 20px 10px;
    border-bottom: 1px solid #979797;
  }
  .details {
    display: flex;
    justify-content: space-between;
    .text {
      position: relative;
      padding-right: 10px;
      font-size: 1.8rem;
      > span {
        display: block;
      }
      .more {
        color: inherit;
        font-size: inherit;
        text-decoration: underline;
        &::after {
          content: '>'
        }
      }
    }
    .button {
      right: 0;
      top: 0;
    }
  }

  .transfer-history-plus-receipt {
    margin-left: 15px;
    color: #1b1b1c;
  }
}

.history-details {
  font-size: 1.8rem;
  dl {
    padding: 30px;
    border-bottom: 1px solid #979797;
  }
  dt {
    position: sticky;
    top: -20px;
    background: rgba(255,255,255,.7);
    z-index: 10;
  }
  .el-button--mini.is-circle {
    width: 18px;
    height: 18px;
    padding: 0;
    background: #fff;
    i {
      display: table;
      margin: 0 auto;
      line-height: 16px;
    }
  }
  .el-button--text {
    font-size: inherit;
    text-decoration: underline;
    &:not(.is-disabled) {
      color: inherit;
    }
  }
  .receipt-button {
    min-width: 61px;
    padding: 5px 8px;
    height: 22px;
    background-color: #f2f6fe;
    border-radius: 8px;
    border: solid 1px #878d91;
    color: #4d5256;
    font-size: 13px;
    & + .receipt-button {
      margin-left: 3px;
    }
  }
}

.el-pagination {
  margin: 30px 0 15px;
  text-align: center;
  font-weight: normal;
  .el-pager {
    li {
      min-width: 30px;
      border: 1px solid transparent;
      border-radius: 4px;
    }
    li:not(.disabled).active {
      border-color: #ced3d6;
      background: #f8fafb;
    }
  }
}

.el-table {
  border-top: 1px solid #ebeef5;
  th, td {
    height: 53px;
    padding: 5px 0;
    box-sizing: border-box;
    .cell {
      line-height: 1.2;
    }
  }
  th {
    background: #f8fafb;
    font-weight: bold;
  }
}

.el-dialog {
  margin-top: 15vh;
  .el-dialog__title {
    font-weight: bold;
  }
}

.dialog-head {
  .el-col + .el-col {
    flex-basis: 0;
    padding-right: 15px;
    box-sizing: border-box;
    button {
      width: 220px;
    }
  }
}

.date-range-picker {
  display: inline-block;
  border: 1px solid #ced3d6;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  .el-date-editor.el-input {
    width: 230px;
    +.el-date-editor.el-input {
      border-left: 1px solid #ced3d6;;
    }
  }
  .el-input__inner {
    width: 100%;
    height: 54px;
    padding: 0 20px 0 70px;
    border: 0;
  }
  .el-input__prefix {
    left: 0;
    width: 54px;
    background: #f8fafb;
    border-right: 1px solid #ced3d6;
    font-size: 2rem;
  }
}

.box-address {
  margin: 0 0 22px;
  .el-card {
    text-align: center;
  }
  h1 {
    margin: 0 0 15px;
  }
  ul {
    max-height: 380px;
    border-top: 1px solid #ebeef5;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .address {
    border-bottom: 1px solid #ebeef5;
    button {
      width: 100%;
      padding: 15px 0 8px;
      word-break: keep-all;
    }
  }
  .road {
    display: block;
    margin: 0 0 10px;
  }
  .jibun {
    display: block;
    color: $color-gray;
    font-size: 1.2rem;
  }

  .el-form-item__error {
    display: block;
    width: 100%;
    font-size: 1.5rem;
  }
}

.el-message {
  top: 0 !important;
  border: 0;
  border-radius: 0 0 6px 6px;
  color: #fff;
  @media (max-width: $break-point-md) {
    width: 90%;
    min-width: auto;
  }
  &.el-message--info {
    color: #4d5256;
  }
  &.el-message--success {
    background: $color-success;
  }
  &.el-message--warning {
    background: #fab005;
  }
  &.el-message--error {
    background: $color-danger;
  }
  .el-message__icon {
    color: inherit;
    font-size: 2.4rem;
  }
  .el-message__content {
    color: inherit;
  }
  .el-message__closeBtn {
    color: inherit;
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.el-message-box {
  width: auto;
  min-width: 420px;
  padding: 0 0 55px;
  border-radius: 8px;
  font-size: inherit;
  overflow: visible;
  &.large-contents {
    .el-message-box__header {
      padding-bottom: 0;
    }
    .el-message-box__content {
      padding-bottom: 35px;
      font-size: 1.9rem;
    }
  }
  .el-message-box__header {
    padding: 60px 60px 25px;
  }
  .el-message-box__title {
    color: #4d5256;
    font-weight: bold;
    font-size: 2.2rem;
  }
  .el-message-box__headerbtn {
    right: -45px;
    top: 0;
    font-size: 4rem;
    line-height: 40px;
    i {
      color: #fff;
    }
  }
  .el-message-box__content {
    padding: 0 60px 45px;
    font-size: inherit;
  }
  .el-message-box__btns {
    padding: 0 60px;
  }
  .el-button {
    min-width: 165px;
    height: 55px;
    font-size: inherit;
  }
}

.el-message-box.el-message-box--center {
  .el-message-box__title {
    display: block;
    text-align: center;
    font-size: 2.65rem;
  }
  .el-message-box__status {
    display: block;
    margin: 0 0 40px;
    font-size: 10rem !important;
  }
  .el-button {
    font-size: 1.7rem;
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 190px;
  color: #a9afb3;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  font-size: 1.8rem;
  text-align: center;
  & > div:nth-child(2) {
    font-size: 1.4rem;
  }
}

.add-transfer-btn-undefined, .add-transfer-btn- {
  position: absolute;
  left: 10px;
  color: black;
  text-decoration: underline;
  font-size: 11px;
}
.add-transfer-btn-is-error {
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: black;
  text-decoration: underline;
  font-size: 11px;
}
