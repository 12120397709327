.home-greeting {
  top: -100px;
  color: #9294a4;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.53px;
}

.home-card-container {
  .corp-snapshot {
    $distance-between-line: 26px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: fit-content;
    width: 1004px;
    margin-top: 40px;
    margin-bottom: 34px;
    .snapshot-card-date {
      display: inline-block;
      margin-top: $distance-between-line;
      min-width: 198px;
      max-width: 22%;
      height: 127px;
      margin-right: 10px;
      padding: unset;
      border: unset;
      box-shadow: unset;
      text-align: left;
      .card-title {
        margin-top: 25px;
        margin-bottom: 5px;
        color: #959faa;
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
      }
      .card-substance {
        color: #1b1b1c;
        font-size: 30px;
        line-height: normal;
      }
      .card-caption {
        color: #b2bac1;
        font-size: 10px;
        font-weight: 500;
        line-height: normal;
      }
    }
    .snapshot-card {
      display: inline-block;
      margin-top: $distance-between-line;
      min-width: 245px;
      max-width: 22%;
      margin-right: 18px;
      height: 127px;
      padding: 36.5px 0 36.5px;
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
      border: solid 1px #eff0f6;
      background-color: #ffffff;
      text-align: center;
      .card-title {
        color: #959faa;
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
        text-align: center;
      }
      .card-substance {
        margin-top: 5px;
        color: #202020;
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
        text-align: center;
      }
    }
  }
}

.home-chart-container {
  width: 1004px;
  padding: 24px 22px 34px 0;
  margin-bottom: 50px;

  .chart-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    .el-select {
      width: 149px;
      height: 40px;
    }
    .el-range-editor {
      width: 240px;
      margin-left: 16px;
      .el-range__close-icon {
        display: none;
      }
    }
    .el-input__inner {
      height: 40px;
      background-color: #fff;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.74px;
      color: #202020;
    }
    .el-input__prefix {
      display: flex;
      align-items: center;
      left: 10px;
      color: #1b1b1c;
    }
    .ico-calender {
      width: 18px;
      height: 18px;
      margin: 0 4px;
      background: url("../images/icons/ico-calendar.svg") no-repeat;
    }
  }
}

.home-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
  p {
    color: #1b1b1c;
    font-size: 32px;
    font-weight: normal;
    line-height: 1.38;
    letter-spacing: -1.07px;
    text-align: center;
  }

  .home-empty-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 58px;

    a {
      text-decoration: none;
      &:first-child {
        margin-right: 12px;
      }
    }
    .el-button {
      width: 234px;
      height: 70px;
      color: #5893ff;
      border-color: #5893ff;
      background-color: #fff;
      &:hover {
        color: #0c62ff;
        border-color: #0c62ff;
      }
    }
  }
}
