.main-header {
  $menu-spacing: 15px;
  position: fixed;
  width: 100vw;
  background: $color-main;
  z-index: 12;
  transition: transform .3s;
  @media (min-width: $break-point-md) {
    width: 100%;
  }
  header {
    padding: 15px 0;
    border-bottom: 1px solid rgba(239, 239, 239, 0.07);
    color: #fff;
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    border-collapse: collapse;
    box-sizing: border-box;
    @media (min-width: $break-point-md) {
      height: fit-content;
    }
  }
  .side-left, .side-right {
    display: flex;
    box-sizing: border-box;
    vertical-align: middle;
  }
  .side-left {
    img {
      position: absolute;
      top: 50%;
      max-width: 99px;
      width: 26.4vw;
      height: auto;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      @media (min-width: $break-point-md) {
        position: relative;
        top: unset;
        max-width: 114px;
        height: 23px;
        width: unset;
        margin-top: 4px;
        -webkit-transform: unset;
        transform: unset;
      }
    }
  }
  .side-right {
    text-align: right;
    position: relative;
    div {
      display: inline-block;
    }
    ul {
      display: none;
      justify-content: flex-end;
      padding-top: 1px;
      list-style: none;
      @media (min-width: $break-point-md) {
       display: flex;
      }
    }
    $menu-divider-margin: 33px;
    ul::after {
      margin-right: $menu-divider-margin;
      color: white;
      font-size: 20px;
      font-weight: bold;
      content: '|';
    }
    li {
      margin-right: $menu-divider-margin;
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
    }
    .link-wrapper {
      padding-top: 0.8rem;
      padding-right: 3%;
      min-width: 18rem;
      text-align: right;
      @media (min-width: $break-point-md) {
        min-width: 50px;
        padding-top: 3px;
        padding-right: 10px;
      }
    }
    .link {
      font-size: 1.5rem;
      font-weight: 600;
      text-decoration: none;
      @media (min-width: $break-point-md) {
        font-size: 16px;
      }
    }
    .language-select {
      width: 6rem;
      height: 3rem;
      margin: 0.4rem 0 0 1rem;
      padding: 0.2rem 0 0.1rem 1.5rem;
      border: 0.1rem solid white;
      border-radius: 2.75rem;
      font-size: 1.3rem;
      font-weight: bold;
      outline: 0;
      appearance: none;
      background: none;

      .language-option {
        background: #1b1b1c;
        color: white;
      }

      @media (min-width: $break-point-md) {
        width: 74px;
        height: 28px;
        padding-top: 2px;
        padding-left: 25px;
        margin-top: unset;
        margin-left: 15px;
        border-radius: 14px;
        border: solid 1px white;
        font-size: 14px;
      }
    }
    .language-img {
      display: none;
      position: absolute;
      width: 13px;
      height: 13px;
      right: 51.5px;
      top: 7px;
      z-index: 99;
      @media (min-width: $break-point-md) {
       display: block;
      }
    }
    .el-icon-arrow-down {
      position: absolute;
      display: inline-block;
      top: 1.3rem;
      right: 1.2rem;
      font-weight: bold;
      font-size: 1.2rem;
      @media (min-width: $break-point-md) {
        top: 7.5px;
        right: 11.5px;
        font-size: 11px;
      }
    }
  }
}

.default-wrapper {
  .sub-head {
    margin: 42px 0 35px;
    padding-right: 15px;
    & > div {
      float: none;

      & + div {
        flex-basis: 0;
        white-space: nowrap;
        .el-button {
          min-width: 220px;
        }
      }
    }

    .sub-title {
      font-size: 0;

      &::before {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
        content: '';
      }

      h2 {
        display: inline-block;
        vertical-align: middle;
        font-weight: bold;
        font-size: 2.2rem;
      }
      h3 {
        font-weight: bold;
        font-size: 1.8rem;
      }

      h5 {
        font-size: 1.4rem;
      }
    }

    .help {
      position: relative;
      left: -15px;
      top: -10px;

      .el-icon-question {
        font-size: 1.8rem;
      }
    }
  }

  .el-footer {
    position: fixed;
    right: 40px;
    bottom: 10px;
    z-index: 4;

    .el-button {
      width: 220px;
    }
  }
}

.country-flag {
  display: inline;
  background-color: #fff;
}

.el-table {
  .summary-row {
    background: #f2f1fa;
  }
}

.manage-table {
  &+.manage-table {
    margin: 50px 0 0;
  }
  .el-table {
    font-size: smaller;
    thead {
      font-size: 1.3rem;
    }
    .header {
      .suffix-icon {
        margin-left: 5px;
        font-size: 1.7rem;
        cursor: pointer;
      }
    }

    .el-form-item.is-button {
      input {
        cursor: pointer;
      }
    }

    .el-form-item__error {
      padding-top: 0;
      font-size: 10px;
    }
    .el-button--mini.is-circle {
      width: 18px;
      height: 18px;
      padding: 0;
      background: #fff;
      i {
        display: table;
        margin: 0 auto;
        line-height: 16px;
      }
    }
    .file-name {
      font-size: xx-small;
      text-decoration: underline;
    }
  }
}

.basic-dialog {
  &.small {
    .el-dialog {
      width: 40%;
      .el-dialog__body {
        padding: 0 25px 40px 25px;
      }
      .default-wrapper {
        margin: 0;
      }
    }
  }
  &.mini {
    .el-dialog {
      width: 30%;
    }
    .el-dialog__header {
      padding: 24px 24px 12px;
    }
    .sub-head {
      margin: 0 0 15px;
      h2 { font-size: 1.6rem; }
    }
    .el-dialog__body {
      padding: 5px 10px 5px;
    }
    .default-wrapper {
      margin: 0 5px 15px 5px;
    }
    .divider {
      margin: 0 0 12px;
    }

    .dialog-slot {
      font-size: 1.4rem;

      h2 {
        margin-bottom: 10px;
        font-weight: bold;
      }

      .rate-list {
        margin-top: 5px;
      }
    }
  }

  &.address {
    .el-dialog {
      width: 855px;
    }
  }

  .default-wrapper {
    margin: 0 20px 60px 20px;
  }
  .divider {
    margin: 0 0 24px;
  }

  .dialog-slot {
    font-size: 1.6rem;
    h2 {
      margin-bottom: 10px;
      font-weight: bold;
    }
    .rate-list {
      margin-top: 5px;
    }
  }

  .close-modal {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

.pdf-dialog {
  display: none;
  position: absolute;
  top: 0;
  width: 21cm;

  .document-container {
    margin: 10mm 15mm 10mm 15mm;
    display: flex;
    flex-direction: column;

    .el-dialog__title {
      font-weight: bold;
      font-size: 20pt;
    }

    .document-content {
      height: 270mm;

      h2 {
        font-size: 16pt;
      }
      h3 {
        font-size: 12pt;
        font-weight: bold;
      }

      .el-divider--horizontal {
        margin: 7mm 0;
        background-color: $color-pdf-middle-high;
      }

      .document-base-form {
        margin-bottom: 5mm;

        .document-table {
          border-collapse: collapse;
          width: 100%;
          .tr {
            display: flex;
          }
          .td {
            padding: 5px 15px;
            border: 1px solid white;
            &.column-title {
              max-width: 35mm;
              min-width: 35mm;
              background-color: $color-pdf-high;
              color: white;
            }
            &.column-title-expand {
              max-width: 70mm;
              min-width: 70mm;
              background-color: $color-pdf-high;
              color: white;
            }
            &.column-question {
              max-width: 150mm;
              min-width: 150mm;
              background-color: $color-pdf-high;
              color: white;
            }
            &.column-question-sub {
              max-width: 150mm;
              min-width: 150mm;
              padding: 5px 0 0 35px;
              background-color: $color-pdf-middle;
              color: black;
            }
            &.column-content {
              width: 100%;
              background-color: $color-pdf-low;
              color: black;
            }
          }
        }

        .textarea {
          background-color: $color-pdf-middle;
          color: black;
          p {
            margin-left: 2mm;
            font-size: 9pt;
          }
          min-height: 50mm;
        }
        .sub-text {
          font-size: 10pt;
        }

        .text-only {
          white-space: pre-wrap;
        }
      }

      .document-confirmation {
        margin-top: 25mm;
        .flex-end {
          margin-top: 5mm;
        }
      }

      .signature {
        width: 85mm;
        height: 45mm;
        margin-top: 5mm;
        background-color: $color-pdf-middle;
        color: black;
        font-size: 9pt;
        &.simple {
          height: 12mm;
        }

        h4 {
          display: inline-block;
          margin: 3mm;
          font-size: 10pt;
          font-weight: bold;
        }
        .text {
          margin: 2mm;
        }
        .sign {
          &-name {
            display: inline-block;
          }
          &-area {
            display: inline-block;
            margin-left: 20mm;
            padding: 3mm 10mm;
            background-color: white;
            &.simple {
              margin-left: 35mm;
              padding: 2mm 8mm;
            }
            &.expand {
              margin-left: 10mm;
              padding: 2mm 4mm 2mm 45mm;
            }
          }
          &-image {
            display: inline-block;
            margin-right: 10mm;
          }
        }
      }
    }

    .document-footer {
      color: #a1a3ae;
    }
  }
}

.flex {
  &-end {
    display: flex;
    justify-content: flex-end;
  }
  &-center {
    display: flex;
    justify-content: center;
  }
  &-space-around {
    display: flex;
    justify-content: space-around;
  }
  &-space-between {
    display: flex;
    justify-content: space-between;
  }
}

.width {
  &-expand {
    width: 100%;
  }
}

.margin-bottom {
  &-mini {
    margin-bottom: 5px;
  }
}

.text-align {
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
}

.font-weight {
  &-bold {
    font-weight: bold;
  }
}

.form-modal {
  .form-item {
    display: block;
  }
  .date-range {
    width: 100%;
  }
  .el-divider--horizontal {
    margin: 15px 0;
  }

  .el-form-item__label {
    display: block;
    margin: 5px 0 5px 5px;
    line-height: 1.5;
  }
  .el-select {
    display: block;

    .el-input__suffix {
      top: 13px;
    }
  }

  .form-submit {
    display: flex;
    justify-content: flex-end;
  }
}

.divided-form {
  h1 {
    margin: 30px 0 0 10px;
    font-weight: bold;
    font-size: 1.5rem;
  }
  h2 {
    margin: 30px 0 0 10px;
    font-weight: bold;
  }
  h3 {
    margin: 15px 0 0 15px;
    font-weight: bold;
  }
  ol {
    list-style-type: decimal;
    li {
      margin-left: 40px;
    }
  }
  .sub-title-button {
    margin-top: 30px;
  }

  &.last-form {
    margin-bottom: 100px;
  }

  .form-item {
    display: inline-block;
    width: calc(50% - 20px);
    margin: 0 10px;
    .form-indent {
      margin-left: 20px;
      .el-radio {
        display: block;
        margin-bottom: 5px;
      }
    }

    .link {
      text-decoration: underline;
      color: $color-primary;
      cursor: pointer;
    }

    &.radio-form-item {
      margin: 0 10px 20px 10px;
    }

    .el-radio__input.is-checked + .el-radio__label {
      color: $color-black;
    }

    .vertical-radio-group {
      display: flex;
      flex-direction: column;
      .el-radio {
        margin: 5px 0 5px 20px;
      }
    }
  }
  .date-range {
    width: 100%;
  }
  .additional {
    margin-left: 20px;
    .button-text {
      text-decoration: underline;
    }
  }
  .form-text {
    margin: 15px 0 0 20px;
  }

  .el-divider--horizontal {
    margin: 15px 0;
    &.inner {
      margin: 25px 15px 10px 15px;
    }
  }

  .el-form-item__label {
    display: block;
    margin: 20px 0 10px 5px;
    padding: 0;
    line-height: 1.5;
  }
  .el-date-editor {
    width: 100%;
  }
  .el-select {
    display: block;
  }

  .el-radio__input.is-checked {
    + .el-radio__label {
      color: $color-success;
    }
    .el-radio__inner {
      border-color: $color-success;
      background: $color-success;
    }
  }

  .form-submit {
    display: flex;
    justify-content: flex-end;
    &.around {
      justify-content: space-around;
    }
  }

  .expand {
    width: calc(100% - 10px);
  }
}

.upload-button-wrapper {
  display: flex;
  align-items: center;
  margin: 5px 0 15px 0;
  p {
    margin: 0 10px;
  }
  .el-button--danger.is-plain {
    color: #ff8e89;
    background: #ffffff;
    border-color: #ffd2d0;
  }
  .el-button--mini.is-circle {
    padding: 4px;
  }

  .form {
    display: flex;
    margin-right: 30px;
    .el-upload-list {
      margin-left: 20px;
      li {
        &:focus {
          outline: none;
        }
        .el-upload-list__item-name {
          margin-right: 55px;
        }
        .el-icon-close {
          display: none;
          margin-left: 10px;
          padding: 3px;
          color: #ff8e89;
          background: #ffffff;
          border-radius: 50%;
          border: 1px solid #ffd2d0;
          font-size: 1.4rem;
          &:focus {
            color: #c25854;
            background: #e6e6e6;
            border-color: #c25854;
          }
        }
        font-size: 1.6rem;
      }
    }
  }
}

.el-button--large {
  height: 55px;
  &.excel {
    width: 260px;
  }
}

.button-expand {
  width: 90%;
}

.dtable-desc {
  margin: 20px 0 0;
}

.color {
  &-primary {
    color: $color-primary;
  }
  &-black {
    color: $color-black;
  }
  &-danger {
    color: $color-danger;
  }
  &-gray {
    color: $color-gray;
  }
}

.aria-hidden {
  position: absolute;
  width: .1em;
  height: .1em;
  opacity: 0;
}

.align-center {
  text-align: center;
}

.block-align {
  &-right {
    display: flex;
    justify-content: flex-end;
  }
}

.link-button {
  cursor: pointer;
  text-decoration: underline;
  &.delete {
    margin-left: 10px;
    color: #949494;
  }
}

.el-button--default {
  background: #f8fafb;
  border-color: #ced3d6;
  &:hover {
    background: #eaeeef;
    border-color: #ced3d6;
    color: inherit;
  }
  &.is-disabled,
  &.is-disabled:hover,
  &.is-disabled:focus {
    opacity: .3;
    background: #eaeeef;
    border-color: #ced3d6;
    color: inherit;
  }
}

.el-button--danger {
  &.is-plain {
    color: #f56c6c;
  }
}

.el-button--primary {
  background: #4c80f1;
  border-color: #4c80f1;
  &:hover {
    background: #2c5ae9;
    border-color: #2c5ae9;
    color: #fff;
  }
  &.is-disabled,
  &.is-disabled:hover,
  &.is-disabled:focus {
    opacity: .3;
    background: #2c5ae9;
    border-color: #2c5ae9;
    color: #fff;
  }

  &.is-plain {
    padding: 12px 20px;
    background: #f2f6fe;
    border-color: #2c5ae9;
    color: #2c5ae9;
    &:hover,
    &:focus {
      background: #dbe6fc;
      border-color: #2c5ae9;
      color: #2c5ae9;
    }
    &.is-disabled,
    &.is-disabled:hover,
    &.is-disabled:focus {
      opacity: .3;
      background: #f2f6fe;
      border-color: #2c5ae9;
      color: #2c5ae9;
    }
    &.el-button--mini {
      padding: 7px 15px;
    }
  }
}

.el-button--info {
  &.is-plain {
    background: #f1f5f5;
    border-color: #ced3d6;
    color: inherit;
    &:hover {
      background: #e1e4e6;
      border-color: #ced3d6;
      color: inherit;
    }
    &.is-disabled,
    &.is-disabled:hover,
    &.is-disabled:focus {
      opacity: .3;
      background: #e1e4e6;
      border-color: #ced3d6;
      color: inherit;
    }
  }
}

.el-dialog__header {
  padding: 40px 40px 10px;
}
.el-dialog__body {
  padding: 30px 40px 30px;
}
