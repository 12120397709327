.register-individual-recipient-modal {
  $el-dialog-left-padding: 44px;
  .el-dialog {
    position: relative;
    border-radius: 4px;
    width: 620px;
    height: 878px;
    overflow: hidden;
    overflow-x: hidden;
    .default-wrapper {
      margin: unset;
      .form-title {
        margin-top: 25px;
        padding-bottom: 6px;
        border-bottom: 1px solid #253339;
        color: #1e1e1e;
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: -1.61px;
      }
      .next-btn {
        position: absolute;
        bottom: 0px;
        width: 620px;
        height: 80px;
        margin-left: (-$el-dialog-left-padding);
        color: #ffffff;
        background-color: #477cf4;
        border: none;
        border-radius: 0px;
        font-size: 20px;
        font-weight: 500;
      }
      .el-button--default.is-disabled {
        background-color: #d8d8d8;
        opacity: 1;
      }
    }
    .el-dialog__header {
      position: absolute;
      background-color: white;
      z-index: 10000;
      width: 620px;
      height: 84px;
      padding: 30px 0px 25px 80px;
      border-bottom: solid 1px #d1d1d1;
      border-radius: 4px;
      .el-dialog__title {
        position: relative;
        color: #1b1b1c;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.91px;
        .title-beside-division {
          margin-right: 3px;
          margin-left: 8px;
          &:first-child {
            margin-left: unset;
          }
        }
        .title-division {
          position: absolute;
          display: inline-block;
          width: 1px;
          height: 17px;
          top: 3.5px;
          background-color: #7c7c7c;
        }
      }
      .el-dialog__headerbtn {
        padding-top: 2px;
        .el-dialog__close::before {
          font-size: 35px;
        }
      }
    }
    .el-dialog__body {
      padding: 49px 0px 0px $el-dialog-left-padding;
      background-color: #f9fafb;
    }
    .register-individual-modal-wrapper {
      position: relative;
      top: -42px;
      left: (-$el-dialog-left-padding);
      width: 620px;
      height: 714px;
      padding: 49px 0px 0px $el-dialog-left-padding;
      overflow-y: scroll;
      #individual-register-form {
        padding-bottom: 80px;
      }
    }
    .divider {
      display: none;
    }
    .register-individual-recipient-form {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-form-item {
        $label-margin-top: 20px;
        .el-form-item__label,
        .el-form-item__content {
          display: table-cell;
        }
        .el-form-item__label {
          flex-basis: 150px;
          width: 150px;
          margin-right: 20px;
          margin-top: $label-margin-top;
          padding: unset;
          color: #636c74;
          text-align: left;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          .el-tooltip {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 2px;
          }
        }
        .el-form-item__content {
          flex-basis: 362px;
          width: 362px;
          .el-select {
            width: 100%;
          }
          .el-radio-group {
            height: 22px;
            margin-top: $label-margin-top;
            .el-radio__label {
              color: #1e1e1e;
              font-size: 16px;
              font-weight: 500;
            }
            .el-radio__inner {
              width: 22px;
              height: 22px;
            }
            .el-radio__input.is-checked .el-radio__inner {
              background-color: white;
              border-color: #cbcbcb;
              &::after {
                width: 12px;
                height: 12px;
                background-color: #6b91f8;
              }
            }
          }
          .el-input__inner {
            height: 60px;
            width: 100%;
            border-radius: 2px;
            background-color: white;
            &::placeholder {
              color: #c5cdd3;
              font-size: 17px;
            }
          }
          .el-input__suffix {
            .el-select__caret {
              margin-right: 11px;
              &::before {
                position: relative;
                top: 2px;
                color: #c5cdd3;
                font-size: 24px;
              }
            }
          }
        }
        .el-form-item__label::before {
          display: none;
        }
      }
      .phone-code-select {
        display: inline;
        position: relative;
        .el-input {
          position: absolute;
          left: 0px;
          bottom: -37.7px;
          width: 140px;
          color: #1b1b1c;
          font-size: 18px;
          font-weight: 500;
          z-index: 100;
        }
      }
      .phone-number-input {
        margin-top: -60px;
        .el-input {
          text-align: right;
          .el-input__inner {
            width: 213px;
            right: 0;
          }
        }
        .el-form-item__error {
          margin-left: 150px;
        }
      }
      .el-form-item__error {
        top: 38px;
        left: 18px;
        color: #e14747;
        font-size: 10px;
        line-height: normal;
        letter-spacing: -0.13px;
      }
      .el-form-item.is-error .el-input__inner {
        border-color: #e14747;
      }
    }
  }
  .dialog-title-icon {
    position: absolute;
    width: 30px;
    margin-top: -21.5px;
    left: 44px;
    z-index: 10005;
  }

  .successfully-created-notice {
    position: relative;
    width: 620px;
    height: 792px;
    margin-top: -40px;
    padding-top: 164px;
    background-color: white;
    text-align: center;
    margin-left: -$el-dialog-left-padding;
    .el-icon-check {
      color: #477cf4;
      font-size: 90px;
    }
    .registered-recipient {
      color: #477cf4;
      font-size: 40px;
      font-weight: bold;
      line-height: 1.23;
      letter-spacing: -1.13px;
      text-align: center;
    }
    .recipient-is-registered {
      color: #1b1b1c;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.75;
      letter-spacing: -0.79px;
      text-align: center;
    }
    .click-register-other-btn {
      display: block;
      margin: 0 auto;
      width: 272px;
      height: 66px;
      margin-top: 52px;
      padding: 22.5px 0px;
      border-radius: 4px;
      border: solid 1px #477cf4;
      background-color: #ffffff;
      span {
        display: inline-block;
        margin: 0 auto;
        color: #477cf4;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: normal;
      }
    }
    .go-to-list {
      display: inline-block;
      text-decoration: none;
      margin-top: 27px;
      color: #1b1b1c;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.36px;
      text-decoration: none;
    }
    .complete-btn {
      position: absolute;
      width: 100%;
      height: 80px;
      bottom: 0px;
      left: 0px;
      color: #ffffff;
      border-radius: 0px;
      border: none;
      background-color: #477cf4;
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
    }
  }
}

.tooltip-in-form {
  position: relative;
  max-width: 407px;
  background-color: #5a8cff !important;
  border-radius: 3px;
  font-size: 11px;
  line-height: 1.55;
  letter-spacing: -0.58px;
  .yellowcolor {
    color: #ffeb00;
    font-size: 11px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.58px;
  }
}

.el-tooltip__popper {
  .popper__arrow,
  .popper__arrow::after {
    border-bottom-color: #5a8cff !important;
  }
}
