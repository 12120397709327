/*
Write your variables here. All available variables can be
found in element-ui/packages/theme-chalk/src/common/var.scss.
For example, to overwrite the theme color:
*/

/* Typography
-------------------------- */
$--font-size-base: 14px !default;

/* Color
-------------------------- */
$--color-primary: #2281f3 !default;
$--color-white: #FFFFFF !default;

$--color-success: #35d48d !default;
$--color-danger: #ff8e89 !default;

$--color-text-regular: #4d5256 !default;

// Background
$--background-color-base: #f8fafb !default;

/* Checkbox
-------------------------- */
$--checkbox-font-size: $--font-size-base !default;

$--checkbox-input-height: 26px !default;
$--checkbox-input-width: 26px !default;

$--checkbox-border-radius: 4px !default;

$--checkbox-input-border: 2px solid #ced3d6 !default;

$--checkbox-checked-font-color: $--color-text-regular !default;
$--checkbox-checked-input-border-color: $--color-success !default;
$--checkbox-checked-background-color: $--color-white !default;
$--checkbox-checked-icon-color: $--color-success !default;

$--checkbox-input-border-color-hover: $--color-success !default;

/* Button
-------------------------- */
$--button-padding-horizontal: 10px !default;

/* Dialog
-------------------------- */
$--dialog-title-font-size: 22px !default;

/* Input
-------------------------- */
$--input-height: 55px !default;
$--input-placeholder-color: #a9afb3 !default;

/* Message
-------------------------- */
$--message-min-width: 550px !default;

/* Pagination
-------------------------- */
$--pagination-color: #4d5256 !default;
$--pagination-hover-color: #4d5256 !default;

/* Select
-------------------------- */
$--select-input-color: $--color-text-regular !default;
// $--select-group-color: $--color-info !default;

/* icon font path, required */
$--font-path: '~element-ui/lib/theme-chalk/fonts';

@import "~element-ui/packages/theme-chalk/src/index";
