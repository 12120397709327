
.signIn-container {
  width: 100%;
  height: 100%;
  background: url("../images/login_bg.png") no-repeat;
  background-size: cover;
  z-index: 1;
  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.89;
    background-color: #000;
    content: '';
    z-index: 2;
  }
}

.signIn-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;
  .signIn-language-button {
    position: absolute;
    left: 50%;
    top: 32px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    @media (min-width: $break-point-md) {
      position: absolute;
      left: unset;
      right: 180px;
      -webkit-transform: unset;
      transform: unset;
    }

    span {
      color: #fff;
      font-weight: bold;
      text-decoration: underline;
    }
    .inactive {
      span {
        color: #a6a6a6;
        font-weight: normal;
        text-decoration: none;
      }
    }
  }

  .beforeDivide::after {
    content: " |";
    margin-left: 8px;
    color: #979797;
  }

  .no-decoration {
    text-decoration: none;
  }

  .forms-wrapper {
    display: flex;
    height: 100%;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      line-height: 1.15;
      img {
        margin-bottom: 8px;
        width: 109px;
      }

      h1 {
        margin-bottom: 40px;
        color: #ffffff;
        font-family: AppleSDGothicNeo, sans-serif;
        font-size: 4rem;
        font-weight: 300;
        letter-spacing: -0.98px;
        text-align: center;
      }
    }

    .forms {
      width: 325px;

      .el-form-item {
        margin: 0 0 16px 0;
        &.is-error {
          margin: 0;
          .el-input__inner {
            border: solid 1px #ff6868;
            background-color: #fdeceb;
          }
        }

        .el-input__inner {
          color: #000;
          font-size: 15px;
          font-weight: bold;
          &:focus {
            border: solid 2px #0c62ff;
            background-color: #dee5f2;
          }
          &:disabled {
            border: solid 1px #949598;
            background-color: #9fa0a2;
            color: #6a6a6a;
          }
          &::placeholder {
            color: #8c8c8c;
            font-weight: normal;
          }
        }

        .el-input__count-inner {
          background-color: rgba(255, 255, 255, 0);
        }

        .el-form-item__error {
          position: relative;
          margin: 3px 0 9px 0;
          color: #ff6565;
          &:before {
            content: '*';
          }
        }
      }

      .el-button {
        width: 100%;
        color: #fff;
        font-size: 17px;
        &.button-submit {
          font-weight: 800;
        }

        &--primary {
          background: #4d7fe0;
          border-color: #4d7fe0;
        }
      }

      .otp-wrapper {
        .message {
          margin: 12px 0;
          color: #2ccdcf;
          text-align: center;
          font-size: 1.2rem;
          font-weight: 500;
        }

        .field-otp {
          .el-input__suffix {
            top: 8px;
            margin-right: 10px;
            color: #4d7fe0;
            font-weight: 900;
          }

          .el-input-group__append {
            width: 100%;
            padding: 0;
            border: 0;
            background: none;
            text-align: center;
          }
        }
      }

      .resend {
        margin: 10px 0 30px 0;
        color: #d3d3d3;
        text-align: center;
        cursor: pointer;
        font-size: 1.2rem;
        text-decoration: underline;
      }
    }

    .first-access {
      margin: 35px 0;
      color: #d3d3d3;
      text-align: center;
    }

    .button-signUp-link {
      &.el-button {
        font-size: 1.3rem;
      }
      border-radius: 29px;
      border: solid 1px #5e5e5e;
      background-color: rgba(0,0,0,0.5);
    }

    .to-tab-pages {
      .el-divider {
        background-color: rgba(231, 231, 231, 0.2);
        &--horizontal {
          margin: 0 0 10px 0;
        }
      }
      .pages-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        a {
          color: #a1a3ae;
          font-size: 1.2rem;
          text-decoration: none;

          &::after {
            margin: 0 10px;
            content: '|';
          }
          &:last-child::after {
            margin: 0;
            content: '';
          }
        }
      }
    }
  }
}
