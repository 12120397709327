.affiliate-account-wrapper {
  h1 {
    font-size: 2.25rem;
    font-weight: bold;
  }
}

.account-manage-container {
  .corp-member-wrapper {
    margin-top: 70px;
    &.sign-up {
      width: 620px;
      .el-form-item {
        margin-left: 0;
      }
    }

    h2 {
      font-size: 1.7rem;
      font-weight: bold;
    }

    .corp-member-table {
      margin: 15px 0;
      width: 505px;
      td:nth-child(3) {
        width: 100px;
      }
      .member-delete {
        color: #f24147;
        text-decoration: underline;
      }
    }
  }

  .add-member-button {
    margin: 20px 0;
    &.sign-up {
      margin: 40px 0;
    }
  }

  .add-member-modal {
    .el-dialog {
      width: 654px;
    }

    .el-dialog__body {
      padding: 0 0 30px 0;
    }

    .el-divider {
      margin: 24px 0;
    }

    .el-form-item:nth-child(2) {
      width: 100px;
      .el-input__suffix-inner {
        position: relative;
        bottom: -24px;
      }
    }
    .el-form-item:nth-child(3) {
      position: relative;
      left: -20px;
      width: 145px;
    }

    h3 {
      padding-left: 40px;
      font-weight: bold;
      font-size: 1.6rem;
    }

    .divided-form-items {
      padding: 0 40px;
      margin-bottom: 60px;
    }

    .add-member-submit {
      margin: 0 40px;
      background-color: #4c80f1;
      color: #f8fafb;
    }
  }
}
