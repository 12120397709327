.wallet-info-card {
  position: relative;
  max-width: 948px;
  height: 145px;
  border: 2px solid #282a2b;
  border-radius: 0;
  overflow: visible;

  .el-card__body {
    display: flex;
    width: 100%;
    height: 145px;
    padding-left: 30px;
  }

  .flex-span {
    display: inline-block;
    justify-content: flex-start;
  }

  .wallet-balance-span {
    min-width: 147px;
    padding-right: 30px;
    border-right: 1px dashed #dadada;
    line-height: normal;
    letter-spacing: normal;

    .currency-balance {
      margin-top: 23.5px;
      color: #282a2b;
      font-size: 22px;
      font-weight: bold;
    }

    .balance {
      color: #4383ff;
      font-size: 25px;
      font-weight: bold;
    }

    .currency {
      color: #4383ff;
      font-size: 25px;
      font-weight: 500;
    }
  }

  .account-details-span {
    margin-left: 30px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;

    .account-details {
      margin-top: 16.5px;
      margin-bottom: 3px;
      color: #282a2b;
      font-size: 12px;
      font-weight: bold;
    }

    .bank-info {
      margin-top: -1.5px;
      color: #7a7a7a;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .action-span {
    position: absolute;
    top: 15px;
    right: 80px;
    margin-right: -55px;

    .action-btn {
      margin-right: 9px;
      margin-top: 3px;
      color: #282a2b;
      text-decoration: underline;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .filter-select {
      width: 126px;
      margin-left: 17px;

      .el-input {
        border: 1px solid #a4a4a4;

        .filter-img {
          position: relative;
          top: 7px;
        }
      }
      .el-input {
        position: relative;
        top: -5px;
        height: 34px;
        .el-input__inner {
          position: relative;
          top: 8.4px;
          padding-left: 15px;
          height: 14px;
          background-color: white;
          border: none;
          border-radius: 0;
          color: #282a2b;
          font-size: 14px;
          font-weight: 600;

          &::placeholder {
            padding-left: 15px;
            color: #282a2b;
            font-size: 14px;
          }
        }
      }

      .is-focus {
        border: 1px solid #4383ff;
      }

      .invisible {
        display: none;
      }

      .filter-popper {
        top: 17.3px !important;
        border-radius: 0;
        border: 1px solid black;
        border-top: none;
        box-shadow: none;

        .popper__arrow {
          display: none;
        }

        ul {
          font-weight: 500;

          .hover {
            color: #4383ff;
            background-color: #f6faff;
            font-weight: 500;
          }
        }
        .el-scrollbar__view {
          padding: 0;
        }
      }

      .el-input__suffix {
        border-left: inherit;

        .el-select__caret {
          position: relative;
          left: 2px;
        }
      }
    }
  }
}

.wallet-view {
  $table-width: 948px;
  width: calc(100vw - 220px);
  margin-left: -60px;
  padding-left: 60px;

  .sub-head {
    position: absolute;
    top: 53px;
    margin-bottom: 13px;
    z-index: 100;
    .sub-title {
      h2 {
        color: #282a2b;
      }
    }
  }

  .fixed-header {
    position: absolute;
    top: $sub-header-height;
    left: 220px;
    width: calc(100vw - 240px);
    padding-top: 92px;
    padding-left: 60px;
    height: 272px;
    background-color: white;
    z-index: 95;
  }

  .is-scrolled {
    box-shadow: -100px 8px 35px rgba(0, 0, 0, 0.12);
  }

  .wallet-activity-contents {
    margin-top: 265px;
  }

  .wallet-view-wrapper {
    width: calc(100vw - 220px);
    height: 252px;
  }

  .waiting-deposit {
    margin-top: -50px;

    .el-table {
      border-top: none;
    }
  }

  .wallet-activity-table {
    margin-bottom: 99px;
    width: $table-width;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    &.waiting-deposit {
      margin-bottom: 85px;
    }

    .activity-table-title {
      padding-bottom: 2px;
      border-bottom: 2px solid black;
      color: black;
      font-size: 16px;
      font-weight: bold;
    }

    th {
      display: none;
    }

    tr {
      height: 94px;

      &:hover > td {
        background-color: #f6faff;
      }
    }

    .el-table__header-wrapper {
      display: none;
    }

    td:first-child {
      .transaction-tag {
        position: relative;
        left: -10px;
        width: 109px;
        padding-left: 17px;
        padding-right: 0;
        height: 33px;
        border-radius: 21.5px;
        background-color: white;
        border: solid 1px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
      }

      img {
        position: absolute;
        top: 10px;
        left: 12px;
      }

      .remittance {
        position: relative;
        padding-top: 1.4px;
        border: 1px solid #4da5e0;
        color: #4da5e0;
        img {
          top: 5.3px;
          left: 8px;
          width: 18px;
          content: url(../../../src/assets/images/wallet/ico-walletlist-remittance.svg);
        }
      }

      .withdrawal {
        position: relative;
        padding-top: 1.8px;
        border: 1px solid #666fc0;
        color: #666fc0;
        img {
          content: url(../../../src/assets/images/wallet/arrow-left-363.svg);
        }
      }

      .deposit {
        position: relative;
        padding-top: 2px;
        border: 1px solid #4d7fe0;
        color: #4d7fe0;
        img {
          content: url(../../../src/assets/images/wallet/arrow-right-363.svg);
        }
      }
    }

    td:nth-child(2) {
      color: #7a7a7a;
      font-size: 15px;
      font-weight: normal;
      .cell {
        margin-top: 1px;
      }
    }

    td:nth-child(3) {
      color: black;
      font-size: 17px;
      font-weight: 600;
      .cell {
        margin-top: 1px;
      }
      .remittance-link {
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }

    td:nth-child(4) {
      padding-right: 48px;
      color: black;
      font-size: 20px;
      font-weight: 800;
      text-align: right;
      .cell {
        margin-top: 1px;
      }
      .currency {
        font-size: 17px;
        font-weight: normal;
      }

      .el-icon-arrow-right {
        position: absolute;
        top: 36.5px;
        right: 23.8px;
        width: 14.2px;
        height: 14.2px;
        color: #d1d1d6;
        font-weight: 900;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .show-more-wrapper {
    position: relative;
    width: $table-width;
    margin-top: -24px;
    margin-bottom: 80px;

    .below-table-btn {
      display: block;
      margin: 0 auto;
      width: 275px;
      height: 58px;
      color: #747474;
      background-color: white;
      border: 1px solid #747474;
      font-size: 18px;
      line-height: normal;
      border-radius: 0;
    }

    .below-deposit {
      margin-top: 20px;
      color: #282a2b;
      border-color: black;
    }
  }

  .no-activity-wrapper {
    width: 944px;
    height: 311px;
    padding-top: 209px;
    margin-top: 150px;
    color: #282a2b;
    font-size: 22px;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: center;
  }
}

.deposit-modal-container {
  height: fit-content;
  border-radius: 0;
  h1 {
    color: #282a2b;
  }
  .el-dialog__header {
    padding: 0 30px;

    .el-dialog__headerbtn {
      top: 14px;
      right: 14px;
      font-size: 40px;

      .el-dialog__close {
        position: relative;
        top: -8px;
        color: #000;
      }
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  header {
    padding: 37px 30px 32px 30px;
    text-align: center;
    font-size: 28px;
    h1 {
      font-weight: bold;
    }

    h2 {
      color: #4383ff;
    }
  }

  section {
    padding: 8px 30px 0 30px;
    height: fit-content;
    background: linear-gradient(white 50%, rgba(198, 114, 224, 0)),
    linear-gradient(rgba(198, 114, 224, 0), white 50%) 100% 0;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
    overflow-y: auto;
    .title {
      border-bottom: 1px solid #282a2b;
      color: #282a2b;
      font-size: 16px;
      font-weight: bold;
      line-height: 2.3;
    }

    .content {
      margin-top: 11px;
      color: #7a7a7a;
      font-size: 14px;
      line-height: 1.71;

      table {
        color: #282a2b;
        font-size: 15px;

        td:nth-child(1) {
          width: 160px;
        }

        .account_number {
          width: fit-content;
          font-weight: bold;
        }

        td button {
          width: 58px;
          height: 23px;
          padding: 2px 6px 1px;
          margin-left: 10px;
          border: 1px solid black;
          text-align: center;
          font-size: 11px;
          font-weight: normal;
        }
      }
    }

    .content-guidelines {
      margin-top: 29px;
      padding: 24px 20px;
      width: 528px;
      border-radius: 2px;
      border: solid 1px #dedede;
      color: #7a7a7a;
      background-color: rgba(200, 200, 200, 0.1);

      &-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.09;
      }

      &-content {
        margin-top: 15px;
        font-size: 15px;
        line-height: 1.47;

        li {
          margin-top: 15px;
        }
      }
      &-detail {
        a {
          color: black;
        }
      }
    }
  }

  footer {
    position: relative;
    margin-top: 45px;
    .button-group {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 79px;
    }

    button {
      height: 100%;
      font-size: 20px;
    }

    .button-next {
      width: 100%;
      color: #fff;
      background-color: #000;

      &:focus {
        border: none;
      }
    }
  }
}
