$color-primary: #2281f3;
$color-black: #4d5256;
$color-gray: #909399;
$color-silver: #ebeef5;
$color-success: #35d48d;
$color-danger: #ff8e89;
$color-pdf-low: #dcdcdc;
$color-pdf-middle: #cfcfcf;
$color-pdf-middle-high: #9e9e9e;
$color-pdf-high: #7d7d7d;
$aside-width: 220px;
$sub-header-height: 56px;
$base-font-size: 1.6rem;
$header-height: 11.5%;
$color-main: #232429;

$break-point-sm: 768px;
$break-point-md: 992px;
$break-point-lg: 1200px;
$break-point-xl: 1920px;
