.terms-wrap {
  padding: 40px 10px;
  border: 1px solid #c0ccda;
  .el-card {
    margin-bottom: 0;
  }
  a {
    color: $color-primary;
  }
  h6 {
    margin: 35px 0 15px 0;
    font-weight: bold;
  }
  .el-card__header h6 {
    margin: 0
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
    th,td {
      padding: 5px 10px;
      border: 1px solid #c0ccda;
    }
    th {
      font-weight: normal
    }
  }
  .terms {
    &.pre {
      white-space: pre-wrap;
    }
    &>ol {
      table th {
        font-weight: bold;
      }
    }
    &>ol.hidden-number {
      padding-left: 0;
    }
  }
}
