.recipient-enlist-container {
  margin-left: 33px;
}

.icon-recipient {
  display: inline-block;
  width: 22px;
  height: 20px;
  margin-right: 3px;

  &.icon-check-person {
    background: url("../images/recipient/ico-check-person.svg") no-repeat;
  }
  &.icon-excel-blue {
    background: url("../images/recipient/ico-excel-blue.svg") no-repeat;
  }
  &.icon-excel-black {
    background: url("../images/recipient/ico-excel-black.svg") no-repeat;
  }
}

.recipient-enlist-introduce {
  display: flex;
  margin: 80px 0 87px 0;
  justify-content: space-between;
  align-items: center;
  letter-spacing: -1px;

  .enlist-introduce-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 45px;
      height: 102px;
      margin-right: 12px;
    }
    p {
      color: #1b1b1c;
      font-size: 31px;
      font-weight: 550;
      line-height: 1.42;
    }
  }

  .enlist-introduce-right {
    display: flex;
    justify-content: center;
    align-items: center;

    .el-button {
      vertical-align: middle;
      width: 275px;
      height: 78px;
      margin-left: 16px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.5px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
      }
    }
  }
}

.recipient-enlist-download {
  padding-bottom: 60px;
  .el-collapse-item__header {
    height: 135px;
    padding: 40px 44px;
    color: #1b1b1c;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    line-height: inherit;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -1.05px;
    &.is-active {
      border-color: #1b1b1c;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .collapse-title {
      div {
        display: flex;
        align-items: center;
      }
    }

    p {
      display: block;
      color: #1b1b1c;
      letter-spacing: -0.68px;
      opacity: 0.49;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .el-collapse-item__arrow {
    opacity: 0.4;
    transform: scale(2.0) rotate(90deg);
    &.is-active {
      transform: scale(2.0) rotate(-90deg);
    }
  }

  .el-collapse-item__content {
    margin-bottom: 60px;
    border: 1px solid #1b1b1c;
    border-top: none;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
