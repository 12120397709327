
.simple-data-table.is-header-font-size-large {
  ::v-deep {
    .el-table__header-wrapper {
      .cell {
        font-size: 1.6rem;  
      }
    }
  }
}
