.dome-alert-modal {
  .el-dialog {
    border-radius: 2px;
    width: 620px;
    .el-dialog__header {
      height: 84px;
      padding: 30px 43px;
      background-color: #ffffff;
      border-radius: 2px;
      .title {
        margin-right: 8px;
        font-weight: 800;
        font-size: 20px;
      }
      .el-tag {
        position: relative;
        top: -2px;
        padding-left: 8px;
        height: 25px;
        line-height: 25px;
        font-weight: 700;
        border: none;
        border-radius: 109px;
        &.normal {
          background-color: #f0fcf1;
          color: #2e9d97;
        }
        &.caution {
          background-color: #fff5e9;
          color: #f2911e;
        }
        &.danger {
          background-color: #fff5f6;
          color: #e96060;
        }
        &.visa {
          background-color: #f5f0ff;
          color: #7739fc;
        }
      }
      .el-divider {
        margin-left: -43px;
        width: 620px;
        height: 0.3px;
        background-color: #d1d1d1;
      }
      .el-dialog__headerbtn {
        margin-top: 10px;
        .el-dialog__close::before {
          font-size: 25px;
        }
      }
    }
    .el-dialog__body {
      border-radius: 2px;
      position: relative;
      margin-top: 1px;
      padding: 40px 42px 0 42px;
      background-color: #f9fafb;
      .searched-period {
        position: absolute;
        top: 20px;
        right: 42px;
        font-size: 10px;
        color: #757575;
      }
      .graph-section-wrapper {
        position: relative;
        margin-bottom: 26px;
        padding: 38px;
        background-color: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 6px;
        .el-divider {
          background-color: #e5e5e5;
        }
      }
      .description {
        margin-bottom: 37px;
        color: #1b1b1c;
        font-weight: normal;
        font-size: 13px;
      }
      .condition-description-section {
        border-radius: 2px;
        margin-left: -42px;
        width: 620px;
        .el-collapse {
          .el-collapse-item {
            .el-icon-info {
              margin-right: 5px;
              color: #9297a0;
              font-size: 15px;
            }
            .title {
              margin: unset;
              color: #1b1b1c;
              font-weight: bold;
              font-size: 14px;
            }
            .description {
              color: #4d555e;
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
            }
            div {
              padding: 0 21px;
              background-color: #f3f4f5;
            }
            .el-collapse-item__header {
              height: 70px;
              .el-collapse-item__arrow {
                transform: rotate(90deg);
              }
              &.is-active {
                .el-collapse-item__arrow {
                  transform: rotate(270deg);
                }
              }
            }
          }
        }
      }
    }
  }
}

.dome-transaction-details-modal {
  overflow: unset;
  .el-dialog {
    margin-top: 10vh !important;
    width: 620px;
    .el-dialog__headerbtn {
      margin-top: 12.5px;
      margin-right: 6px;
      .el-icon-close::before {
        font-size: 25px;
      }
    }
    .el-dialog__body {
      margin-top: 30px;
      padding-top: 50px;
      padding-left: 22px;
      padding-right: 22px;
      height: 80vh;
      overflow: scroll;
      .default-wrapper {
        margin-bottom: unset;
        .sub-head {
          position: absolute;
          top: 30px;
          z-index: 2003;
          .sub-title {
            h2 {
              font-size: 20px !important;
            }
          }
          margin-bottom: 30px;
        }
        .el-divider {
          position: absolute;
          top: 30px;
          margin-top: 50px;
          margin-left: -42px;
          width: 620px;
          z-index: 2003;
        }
        .title {
          margin-bottom: 11px;
          color: #1b1b1c;
          font-size: 16px;
          font-weight: 700;
        }
        .memo-input-and-button-wrapper {
          padding-top: 20px;
          border-top: 1px solid #1b1b1c;
          .memo-input-and-button {
            margin-bottom: 20px;
          }
          .dome-memo-line-wrapper {
            position: relative;
            height: 35px;
            &.input-shown {
              height: 40px;
            }
            .created-at {
              display: inline-block;
              width: 68px;
              margin-right: 16px;
              font-size: 13px;
              font-weight: normal;
              color: #757575;
            }
            .content-wrapper {
              display: inline-block;
              position: relative;
              width: 76%;
              .content {
                font-size: 13px;
                font-weight: 400;
                color: #1b1b1c;
              }
            }
            .action-icon {
              position: relative;
              margin-right: 16px;
            }
            .action-icon::after {
              position: absolute;
              left: 40%;
              top: 19px;
              content: '|';
              color: #d4d7dc;
              font-size: 10px;
            }
            .el-icon-success {
              color: #70d168;
            }
            .el-icon-error {
              color: #e65757;
            }
            .input-and-btns-wrapper {
              .memo-input {
                display: inline-block;
                margin-right: 10px;
                width: 51%;
                .el-input__inner {
                  height: 35px;
                }
              }
              .input-content-btn {
                position: relative;
                border: unset;
                width: 11%;
                height: 35px;
                padding-top: unset;
                padding-bottom: unset;
                z-index: 30;
                span {
                  font-size: 12px;
                }
              }
            }
            .el-icon-more {
              position: absolute;
              cursor: pointer;
              top: 8px;
              right: 10px;
              font-size: 10px;
              z-index: 0;
              transform: rotate(90deg);
            }
            &.last-line {
              .action-icon::after {
                content: none;
              }
            }
          }
        }
      }
    }
  }
}

.popover-in-dome-memo {
  &.el-popover {
    margin-right: unset;
    padding-top: 8px;
    padding-bottom: 5px;
    min-width: unset;
    text-align: center;
    .btn-in-popover {
      margin-left: unset;
      margin-bottom: 9px;
      padding-top: unset;
      padding-bottom: unset;
      span {
        color: #1b1b1c;
        font-weight: 400;
        font-size: 11px;
      }
      &.is-disabled {
        span {
          color: #c0c4cc;
        }
      }
    }
  }
}
