// Reset
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline
}

html, body, #app, #app > .el-container {
  height: 100%;
}

html {
  font-size: 10px;
}

body {
  overflow: hidden;
  overflow-x: auto;
  font-family: "Apple SD Gothic Neo", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $color-black;
}

.el-main {
  padding: unset;
  width: 100%;
  @media (min-width: $break-point-md) {
    padding: 60px 60px 0px 60px;
    min-width: 804px;
  }
  &.no-padding {
    padding: 0;
  }
}

*, *::before, *::after {
  box-sizing: border-box;
}

ol, ul{
  list-style: none
}

img, video {
  max-width: 100%;
}

a {
  color: inherit;
  &.el-button {
    text-decoration: none;
  }
}

b {
  font-weight: bold;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  color: inherit;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
