
.standard-info-modal {
  .description {
    margin: 20px 0;
    font-size: 1.5rem;
    line-height: 1.44;
    font-weight: bold;
  }
}

.swift-update-modal {
  .swift-form {
    .el-form-item {
      margin-bottom: 15px;

      .el-form-item__label {
        display: block;
        margin: 5px 0 5px 5px;
        padding: 0;
        font-weight: bold;
        line-height: 1.5;
      }
    }

    .el-divider {
      margin: 24px 0 12px 0;
    }
  }
}

.other-countries-modal {
  .el-autocomplete {
    width: 80%;
  }
}

.submit-button {
  display: flex;
  justify-content: flex-end;

  .el-button {
    width: 100px;
  }
}

.description-area {
  width: 600px;
  &.modal {
    width: 100%;
  }
  dl {
    margin: 20px 0 0;
    padding: 15px;
    border: 1px solid $color-primary;
    border-radius: 6px;
    font-size: 1.3rem;
  }
  dt {
    margin: 0 0 5px;
    color: $color-black;
    font-weight: bold;
    font-size: 1.5rem;
  }
  dd {
    .bluecolor {
      color: $color-primary;
    }
    .small {
      font-size: 1.2rem;
    }
    .layout {
      tr + tr td {
        padding-top: 10px;
      }
      td:first-child {
        white-space: nowrap;
      }
      td + td {
        padding-left: 5px;
      }
    }
  }
  .bluecolor {
    color: $color-primary;
  }
}
