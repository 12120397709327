.el-header {
  left: $aside-width;
  padding: 0;
  color: #333;

  .main-header-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: $sub-header-height;
    border-bottom: 1px solid #dedede;
  }

  .header-scroll-title {
    display: none;
    color: #fff;
    .depth-first {
      padding: 0 55px;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.3px;
    }
    a {
      margin-right: 44px;
      color: #8d8d8d;
      text-decoration: none;
      &.router-link-exact-active {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  &.is-scrolled {
    background-color: #1b1b1c;
    .main-header-wrapper {
      justify-content: space-between;
    }
    .header-scroll-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header-settings {
      color: #fff;
      svg g {
        fill: #fff;
      }
    }
  }

  .header-settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .icon-alarm,
    .icon-setting {
      margin-right: 13px;
    }
  }

  .header-user-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 36px;

    .header-user-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
    }

    p {
      font-size: 11px;
      line-height: 1.1;
      &:first-child {
        margin-bottom: 3px;
        font-size: 12px;
        font-weight: bold;
      }
    }
    i {
      margin-left: 13px;
    }
  }
}
.header-user-popper {
  right: 20px;
  margin-top: 22px !important;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .el-button--text {
    padding: 5px 0;
    color: #1b1b1c;
    letter-spacing: -1;

    i,
    svg {
      margin-right: 5px;
    }
  }
}

$nav-padding: 36px;
$nav-footer-padding: 24px;
.main-aside {
  width: $aside-width !important;
  background: #1b1b1c;
}

.navi-header-wrapper {
  background: #fff;
}
.navi-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 168px;
  padding: 0 $nav-padding;
  background: #1b1b1c;

  .logo {
    width: 92px;
  }
}

$radius-size: 32px;
.main-navigation {
  border: none;
  background: #1b1b1c;
  padding-left: 24px;
  margin-bottom: 27px;

  .el-menu-item {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0;
    color: #8d8d8d;
    font-size: 18px;
    font-weight: bold;
    line-height: 17px;
    white-space: pre-wrap;
    word-break: break-word;
    transition: all 1ms;

    .main-navigation-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 147px;
      text-decoration: none;
      span {
        display: inline-block;
        padding-left: 23px;
      }
      i {
        display: inline-block;
        position: absolute;
        padding-bottom: 3px;
      }
    }

    &:hover {
      background-color: unset;
      color: #fff;
      svg g {
        fill: #fff;
      }
      svg path {
        stroke: #fff;
      }
    }
  }

  .el-menu-item.is-active {
    background-color: #fff;
    color: #1b1b1c;
    border-radius: $radius-size 0 0 $radius-size;
    transition: all 1ms;

    svg g {
      fill: #1b1b1c;
    }
    svg path {
      stroke: #1b1b1c;
    }

    &::before {
      position: absolute;
      right: 0;
      top: -$radius-size;
      height: $radius-size;
      width: $radius-size;
      background-color: #fff;
      content: '';
    }

    &::after {
      position: absolute;
      right: 0;
      top: $radius-size + $radius-size;
      height: $radius-size;
      width: $radius-size;
      background-color: #1b1b1c;
      border-top-right-radius: $radius-size;
      content: '';
    }

    .main-navigation-item {
      &::before {
        position: absolute;
        right: 0;
        top: -$radius-size;
        height: $radius-size;
        width: $radius-size;
        background-color: #1b1b1c;
        border-bottom-right-radius: $radius-size;
        content: '';
      }

      &::after {
        position: absolute;
        right: 0;
        top: $radius-size + $radius-size;
        height: $radius-size;
        width: $radius-size;
        background-color: #fff;
        content: '';
      }
    }
  }
}

.nav-wallet {
  padding: 0 $nav-padding;
  border-top: 1px solid #000;
  color: #fff;

  .nav-title {
    display: inline-block;
    padding: 28px 0 0 0;
    font-size: 17px;
    font-weight: 500;
  }

  a {
    text-decoration: none;
  }

  .wallet-balance {
    display: flex;
    align-items: center;
    padding: 15px 0;
    letter-spacing: -0.1px;
    border-bottom: 1px dashed rgba(151, 151, 151, 0.5);
    &:last-child {
      border-bottom: none;
    }

    .country-flag {
      margin-right: 6px;
      width: 18px;
      height: 18px;
      border-radius: 8px;
    }

    &.letter-small {
      font-size: 13px;
    }

    .el-badge {
      &:hover {
        .wallet-underline {
          display: block;
        }
      }
      .wallet-underline {
        display: none;
        position: absolute;
        top: 20px;
        margin: 0;
        &.is-active {
          display: block;
        }
      }
      span {
        font-size: 15px;
        &:nth-child(2) {
          padding-left: 4px;
          opacity: 0.53;
        }
      }
    }

    .el-badge__content {
      background-color: #ff0000;
      border: none;

      &.is-dot {
        right: -5px;
      }
    }
  }
}

.nav-footer {
  position: absolute;
  bottom: 0;
  height: auto;
  color: #8d8d8d;

  a {
    text-decoration: none;
  }

  .is-cs-active h1 {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  h1 {
    padding-left: $nav-padding;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
  }

  .footer-link-wrapper {
    width: $aside-width;
    padding-left: $nav-padding;
    background-color: #1f1e1e;
  }
  .footer-link {
    padding: 16px 0;
    a {
      display: block;
      opacity: 0.67;
      color: #fff;
      line-height: 2;
      font-size: 10px;

      &:hover,
      &:focus {
        opacity: 1;
      }
      &.privacy {
        font-weight: bold;
      }
    }

    .is-policy-active {
      font-weight: bold;
      opacity: 1;
    }
  }

  .footer-bottom {
    .company-name {
      display: inline;
      margin-right: 66px;
      opacity: 0.41;
      color: #fff;
      font-size: 9px;
      font-weight: 500;
    }
    .language {
      display: inline;
    }
    .language button {
      margin: 0;
      color: #fff;
      font-size: 11px;
      font-weight: 800;
      &.inactive {
        opacity: 0.73;
        color: #b0b0b0;
        font-weight: 500;
      }

      &:focus,
      &:hover {
        color: #fff;
        opacity: 1;
      }
    }
  }
}

.default-title-header {
  visibility: visible;
  margin: 0 0 14px 0;
  height: auto;
  letter-spacing: -1px;

  h1 {
    margin-bottom: 44px;
    color: #1b1b1c;
    font-size: 36px;
    font-weight: bold;
    &.dashboard {
      margin-bottom: 0;
    }
  }
  a {
    color: #b5b5b5;
    font-size: 20px;
    text-decoration: none;
    .link-label {
      padding-bottom: 2px;
    }
    .link-divider {
      margin: 0 15px;
      color: #c8c8c8;
    }

    &.router-link-exact-active .link-label {
      color: #1b1b1c;
      border-bottom: 3px solid #1b1b1c;
      font-weight: bold;
      z-index: 2;
    }
  }

  .link-underline {
    background-color: #000;
    margin: 0.5px 0 24px 0;
    padding: 0;
    opacity: 0.1;
    z-index: 1;
  }

  &.is-scrolled {
    display: inline-block;
    visibility: hidden;
    margin-bottom: 20px;
    height: 0;
    font-size: 16px;
    transform: scale(0.2);
    transition: all 0.3s ease;
  }
}
