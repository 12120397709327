.usage-manual-container {
  article {
    display: flex;
    align-items: center;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 80px;
    margin: 40px 0;
    background: #1b1b1c;
    color: #f1f1f1;
    border: #5e5e5e 1px solid;
    font-size: 19px;
    text-decoration: none;
    i {
      margin-right: 15px;
    }
    &:hover {
      color: #fff;
      border-color: #9B9B9B;
    }
  }
}

.faq-view-area > div.download {
  margin-top: 33px;
  margin-bottom: 30px;
  font-size: 1.7rem;
}

.tab-faq-view-div {
  width: 100vw;
  height: 100%;
  padding: 60px 0;
  margin: -60px;
  .faq-area {
    padding-bottom: 50px;
  }
}

.faq-area {
  max-width: 80vw;
  margin: 0 auto;
  &.inner .sub-head {
    margin: 10px 0;
  }

  .collapse-wrapper {
    margin-bottom: 1px;
  }

  .question {
    position: relative;
    padding: 0 27px 0 30px;
    height: fit-content;
    font-size: 1.7rem;
    &::before {
      content: 'Q.';
      position: absolute;
      left: 0;
      top: 0;
      color: $color-primary;
      font-size: 1.7rem;
    }
  }

  .el-collapse-item__header {
    &.is-active {
      font-weight: bold;
    }
  }

  .answer {
    position: relative;
    white-space: pre-line;
    padding: 30px 58px;
    background-color: #f6f7f8;
    font-size: 1.6rem;
    table {
      border-collapse: collapse;
      width: 100%;
      margin: 0 auto;
    }

    table, th, td {
      border: 1px solid #9B9B9B;
    }

    th, td {
      padding: 15px;
    }

    td {
      vertical-align: middle;
      padding: 8px 15px;

      &:first-child {
        min-width: 150px;
      }
    }

    th {
      font-weight: bold;
    }
  }
}
